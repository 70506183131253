// src/composables/useAuthRedirector.js

import { watch } from 'vue';
import {useCoreStore} from "@/stores/CoreStore";

/**
 * Watches Pinia auth state within a component.
 * If auth loading finishes and the user is not authenticated,
 * redirects to the specified login route.
 * Automatically cleans up the watcher when the component unmounts.
 *
 * @param {string} loginRoute - The path to redirect to (e.g., '/login'). Defaults to '/login'.
 */
export function useAuthRedirector() {
    const authStore = useCoreStore();

    console.log('Setting up auth redirect watcher...');

    const stopWatch = watch(
        // 1. Source: Watch multiple states from the store
        [() => authStore.auth.loading, () => authStore.auth.authenticated],

        // 2. Callback: Executed when sources change or immediately
        ([isLoading, isAuthenticated]) => {
            console.log(`Auth state check: loading=${isLoading}, authenticated=${isAuthenticated}, currentRoute=${authStore.page}`);

            // 3. Condition: Loading is complete AND user is not logged in
            if (isLoading === false && isAuthenticated === false) {

                // 4. Action: Redirect if not already on the login page
                if (authStore.page !== 'pg-login') {
                    console.warn(`Redirecting to Login: Authentication required or failed.`);
                    // Use replace to avoid adding the current page to history
                    authStore.page = 'pg-login'
                }
            }
        },

        // 5. Options: Run the check immediately when the composable is used
        { immediate: true }
    );
}