<template>
  <div class="min-h-screen bg-gray-100 flex justify-center items-center">
    <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
      <h2 class="text-2xl font-bold mb-4 text-gray-900">EDO AI Proof Of Concept v0.1</h2>

      <ol class="flex items-center w-full text-xs text-gray-900 font-medium sm:text-base">
        <li class="flex w-full relative text-indigo-600 after:content-[''] after:w-full after:h-0.5 after:bg-indigo-600 after:inline-block after:absolute lg:after:top-5 after:top-3 after:left-4"
            :class="{ 'text-indigo-600': currentStep === 1, 'text-gray-900': currentStep !== 1 }">
          <div class="block whitespace-nowrap z-10">
            <span class="w-6 h-6 rounded-full flex justify-center items-center mx-auto mb-3 text-sm lg:w-10 lg:h-10"
                  :class="{ 'bg-indigo-600 border-2 border-transparent text-white': currentStep === 1, 'bg-indigo-50 border-2 border-indigo-600 text-indigo-600': currentStep !== 1 }">1</span>
            Upload Image
          </div>
        </li>
        <li class="flex w-full relative text-gray-900 after:content-[''] after:w-full after:h-0.5 after:inline-block after:absolute lg:after:top-5 after:top-3 after:left-4"
            :class="{ 'after:bg-indigo-600 text-indigo-600': currentStep >= 2, 'after:bg-gray-200': currentStep < 2 }">
          <div class="block whitespace-nowrap z-10">
            <span class="w-6 h-6 rounded-full flex justify-center items-center mx-auto mb-3 text-sm lg:w-10 lg:h-10"
                  :class="{ 'bg-indigo-600 border-2 border-transparent text-white': currentStep === 2, 'bg-indigo-50 border-2 border-indigo-600 text-indigo-600': currentStep > 2, 'bg-gray-50 border-2 border-gray-200': currentStep < 2 }">2</span>
            Edit Image
          </div>
        </li>
        <li class="flex w-full relative text-gray-900 after:content-[''] after:w-full after:h-0.5 after:inline-block after:absolute lg:after:top-5 after:top-3 after:left-4"
            :class="{ 'after:bg-indigo-600 text-indigo-600': currentStep >= 3, 'after:bg-gray-200': currentStep < 3 }">
          <div class="block whitespace-nowrap z-10">
            <span class="w-6 h-6 rounded-full flex justify-center items-center mx-auto mb-3 text-sm lg:w-10 lg:h-10"
                  :class="{ 'bg-indigo-600 border-2 border-transparent text-white': currentStep === 3, 'bg-indigo-50 border-2 border-indigo-600 text-indigo-600': currentStep > 3, 'bg-gray-50 border-2 border-gray-200': currentStep < 3 }">3</span>
            Customise
          </div>
        </li>
        <li class="flex w-full relative text-gray-900 after:content-[''] after:w-full after:h-0.5 after:inline-block after:absolute lg:after:top-5 after:top-3 after:left-4"
            :class="{ 'after:bg-indigo-600 text-indigo-600': currentStep >= 4, 'after:bg-gray-200': currentStep < 4 }">
          <div class="block whitespace-nowrap z-10">
            <span class="w-6 h-6 rounded-full flex justify-center items-center mx-auto mb-3 text-sm lg:w-10 lg:h-10"
                  :class="{ 'bg-indigo-600 border-2 border-transparent text-white': currentStep === 4, 'bg-gray-50 border-2 border-gray-200': currentStep < 4 }">4</span>
            Review
          </div>
        </li>
      </ol>

      <div id="stepsContainer" v-if="!loading">
        <div id="stepUpload" class="stepContent"  v-show="currentStep === 1">
          <div class="flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500" @click="triggerFileUpload">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4-4m0 0L8 12m4-4v12" />
            </svg>
            <p class="mt-2 text-sm text-gray-600">Drag & drop your image here, or <span class="text-blue-600 underline">browse</span></p>
            <input id="fileUpload" type="file" class="hidden" accept="image/*" @change="handleFileUpload" ref="fileInput" />
          </div>

          <div v-if="image.fileName.length > 0">
            Uploaded Image: {{image.fileName}}
          </div>
        </div>
        <div id="stepEdit" v-show="currentStep === 2" style="width: 100%; height: 100%; background-color: rgb(248, 248, 248);">
          <p>Please crop your image so it is limited to your house</p>
          <!-- The component imported from `vue-picture-cropper` plugin -->
          <VuePictureCropper
              :boxStyle="{
                width: '100%',
                height: '100%',
                backgroundColor: '#f8f8f8',
              }"
              :img="image.preview"
              :options="{
                viewMode: 1,
                dragMode: 'move',
                cropBoxResizable: true,
              }"
              :presetMode="{
                mode: 'fixedSize',
                width: 600,
                height: 600,
              }"
              @ready="windowResize"
          />
        </div>
        <div id="stepCustomize" class="stepContent" v-show="currentStep === 3">
          <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
            <p class="font-bold">AI Is Experimental</p>
            <p>AI's like ours are not always accurate, please check what it has found and modify it as needed.</p>
          </div>
          <div class="mb-4">
            <!-- Doors Category -->
            <h5 class="text-lg font-semibold text-gray-900 mb-2">Doors</h5>
            <div class="grid grid-cols-1 gap-4">
              <!-- Example Door Card -->
              <div class="bg-gray-100 p-4 rounded-lg shadow flex flex-col items-center" v-for="(door, index) in doors" v-bind:key="door.id" >
<!--                <img :src="image.croppedBase64" :style="{ clipPath: clipPath(door) }" alt="Door Image" class="w-24 h-24 object-cover rounded-md mb-3">-->
                <h6 class="font-semibold text-gray-800">Door {{ door.id }}</h6>
                <h6 class="font-semibold text-gray-800">{{ door.name }}</h6>
                <p class="text-sm text-gray-600">{{ door.style }}, {{door.colour}}, {{ door.glass_features.join(',') }}, {{ door.hardware_features.join(',') }}</p>
                <div class="flex justify-around mt-3 w-full">
                  <button @click="removeDoor(index)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs">Remove</button>
                  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs">Edit</button>
                  <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-xs">Edit In Designer</button>
                  <button class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs">Upload A Design</button>
                </div>
              </div>
              <!-- More Door Cards -->
            </div>
            <!-- Windows Category -->
            <h5 class="text-lg font-semibold text-gray-900 mb-2 mt-4">Windows</h5>
            <div class="grid grid-cols-1 gap-4">
              <!-- Example Window Card -->
              <div class="bg-gray-100 p-4 rounded-lg shadow flex flex-col items-center"  v-for="(window, index) in windows" v-bind:key="window.id">
<!--                <img :src="image.croppedBase64" :style="{ clipPath: clipPath(window) }" alt="Window Image" class="w-24 h-24 object-cover rounded-md mb-3">-->
                <h6 class="font-semibold text-gray-800">Window {{ window.id }}</h6>
                <h6 class="font-semibold text-gray-800">{{ window.name }}</h6>

                <p class="text-sm text-gray-600">{{ window.style }}, {{window.colour}}, {{ window.glass_features.join(',') }}, {{ window.hardware_features.join(',') }}</p>
                <div class="flex justify-around mt-3 w-full">
                  <button @click="removeWindow(index)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs">Remove</button>
                  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs">Edit</button>
                  <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-xs">Edit In Designer</button>
                  <button class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs" @click="uploadStyle(index)">Upload A Design</button>
                </div>
              </div>
              <!-- More Window Cards -->
            </div>

            <!-- Upload Modal -->
            <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" v-if="uploadModal" id="upload-modal" style="z-index:200">
              <div class="relative top-20 mx-auto p-5 border w-3/4 md:w-1/2 lg:w-1/3 shadow-lg rounded-md bg-white" v-if="!hasUploadResults">
                <div class="flex justify-between items-center pb-3">
                  <p class="text-2xl font-bold text-gray-600">Upload Design</p>
                  <div class="cursor-pointer z-50" @click="closeUploadModal">
                    <svg class="fill-current text-gray-600" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500" @click="triggerFileUpload">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4-4m0 0L8 12m4-4v12" />
                  </svg>
                  <p class="mt-2 text-sm text-gray-600">Drag & drop your image here, or <span class="text-blue-600 underline">browse</span></p>
                  <input id="fileUpload" type="file" class="hidden" accept="image/*" @change="handleStyleUpload" ref="fileInput" />
                </div>
              </div>
              <div class="relative top-20 mx-auto p-5 border w-3/4 md:w-1/2 lg:w-1/3 shadow-lg rounded-md bg-white" v-else>
                <div class="flex justify-between items-center pb-3">
                  <p class="text-2xl font-bold text-gray-600">Detected Styles</p>
                  <div class="cursor-pointer z-50" @click="closeUploadModal">
                    <svg class="fill-current text-gray-600" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center p-4 border-2  border-gray-300 rounded-lg cursor-pointer ">
                  <ol>
                    <li><b>Style:</b> {{ uploadResults.style }}</li>
                    <li><b>Colour:</b> {{ uploadResults.colour }}</li>
                    <li><b>Georgian Bars:</b> {{ uploadResults.georgian_bars }}</li>
                    <li><b>Hardware Features:</b> {{ uploadResults.hardware_features.join(',') }}</li>
                    <li><b>Glass Features:</b> {{ uploadResults.glass_features.join(',') }}</li>
                  </ol>

                  <button id="applyStyle" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center" @click="applyUploadStyle">
                     Apply Style
                  </button>
                  <button id="applyStyle" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded inline-flex items-center" @click="closeUploadModal">
                    Cancel
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div id="stepAddToCart" class="stepContent" v-show="currentStep === 4">
          <p>Add to cart (mockup)</p>
        </div>
        <div class="flex justify-between mb-4">
          <button id="prevStep" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                  @click="prevStep" :disabled="currentStep === 1">
            &larr; Prev
          </button>
          <button id="nextStep" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                  @click="nextStep">
            {{ currentStep === totalSteps ? 'Finish' : 'Next →' }}
          </button>
        </div>
      </div>
      <div v-else>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Arvo&family=Inconsolata:wght@400;500;700&display=swap" rel="stylesheet">
        <div style="position: relative; width: 100%; height: 40VH">
        <div>
          <div class="blob"><h1>Analyzing...</h1></div>
          <div class="blob1"></div>
          <div class="blob2"></div>
          <div class="blob3">
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import axios from "axios";

export default {
  name: "AiAssistPage",
  components: {
    VuePictureCropper,
  },
  data() {
    return {
      currentStep: 1,
      totalSteps: 4,
      loading: false,
      image: {
        file: null,
        fileName: '',
        preview: null,
        croppedBase64: '',
        croppedBlob: ''
      },
      style: {
        file: null,
        fileName: '',
        preview: null,
        croppedBase64: '',
        croppedBlob: ''
      },
      elevationDetails: {},
      baseUrl: '',
      axios: null,
      windows: [],
      doors: [],
      uploadType: 'windows',
      uploadModal: false,
      uploadIndex: null,
      hasUploadResults: false,
      uploadResults: null
    };
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_API_URL

    // Create Axios instance with base URL
    this.axios = axios.create({
      baseURL: this.baseUrl,
    });
  },
  computed: {
    clipPath() {
      return (door) => {
        const coordinates = door.image_coordinates.map(([x, y]) => `${(x)}px ${(y)}px`).join(",");
        return `polygon(${coordinates})`;
      };
    },
  },
  methods: {
    applyUploadStyle() {
      this.windows[this.uploadIndex].style = this.uploadResults.style
      this.windows[this.uploadIndex].colour = this.uploadResults.colour
      this.windows[this.uploadIndex].georgian_bars = this.uploadResults.georgian_bars
      this.windows[this.uploadIndex].hardware_features = this.uploadResults.hardware_features
      this.windows[this.uploadIndex].glass_features = this.uploadResults.glass_features
      this.windows[this.uploadIndex].window_type = this.uploadResults.window_type
      this.uploadResults = null;
      this.hasUploadResults = false;
      this.uploadModal = false;
      this.uploadIndex = null;
    },
    uploadStyle(index) {
      this.uploadModal = true
      this.uploadIndex = index
    },
    closeUploadModal() {
      this.uploadResults = null;
      this.hasUploadResults = false;
      this.uploadModal = false;
      this.uploadIndex = null;
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        if (this.currentStep == 2) {
          return this.processInitalImage();
        }

        this.currentStep++;

        if (this.currentStep == 2) {
          this.windowResize();
        }
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;

        if (this.currentStep == 2) {
          this.windowResize();
        }
      }
    },
    windowResize() {
      console.log("Window Resize - Force Event")
      setTimeout(function () {
        window.dispatchEvent(new Event('resize'));
      }, 250)
    },
    handleFileUpload: function (event) {
      this.image.file = event.target.files[0];
      this.image.fileName = this.image.file.name;


      let reader = new FileReader();
      reader.readAsDataURL(this.image.file);

      reader.onload = (e) => {
        this.image.preview = e.target.result;
      };

      this.nextStep()
    },
    async handleStyleUpload (event) {
      this.style.file = event.target.files[0];
      this.style.fileName = this.style.file.name;
      this.loading = true;

      let reader = new FileReader();
      reader.readAsDataURL(this.style.file);

      reader.onload = async (e) => {
        this.style.preview = e.target.result;

        let response = await this.axios.post('/api/ai-assist/analyze/window', {
          'image': this.style.preview,
          'extension': this.style.fileName
        });

        this.loading = false;

        this.uploadResults = response.data[0]
        this.hasUploadResults = true;
      };
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    /**
     * Get cropping results
     */
    async processInitalImage() {
      if (!cropper) return
      const base64 = cropper.getDataURL()
      const blob = await cropper.getBlob()
      if (!blob) return

      const file = await cropper.getFile({
        fileName: 'elevationanalysis',
      })

      this.image.croppedBase64 = base64
      this.image.croppedBlob = URL.createObjectURL(blob)
      this.loading = true;

      try {
        let response = await this.axios.post('/api/ai-assist/analyze/elevation', {
          'image': "" + this.image.croppedBase64,
          'extension': file.name
        });

        console.log(response);
        this.elevationDetails = response.data[0];
        this.doors = this.elevationDetails.doors;
        this.windows = this.elevationDetails.windows;
        this.loading = false;
        this.currentStep++;

      } catch (e) {
        console.error("An Error Occurred: " + e);
        this.loading = false;
      }
    },
    removeWindow(index) {
      this.windows.splice(index, 1);
    },
    removeDoor(index) {
      this.door.splice(index, 1);
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

body { background: #FCFAF8; }

h1 {
  position: relative;
  font-size: 22px;
  font-family: 'Arvo', sans-serif;
  font-weight: 400;
  text-align: center;
  line-height: 7;
  overflow: hidden;
  background: linear-gradient(90deg, #ffffff50, #ffffff99, #ffffff50);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -1000%;
  }
  100% {
    background-position: 1000%;
  }
}

.blob {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(45deg, #477159 0%, #acfa70 100%);
  box-shadow: 0 0 200px 0 rgba(255, 0, 153, 0.7), 0 0 50px 0 rgba(94 151 127, 0.7);
  background-size: 200% 200%;
  animation: ai-blob 3s, gradient 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index:4;
}

.blob1 {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #79d381;
  animation: ai-blob 4s linear infinite;
  animation-direction: reverse;
  z-index:3;
}

.blob2 {
  width: 230px;
  height: 230px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #79d381;
  animation: ai-blob 5s linear infinite;
  z-index:2;
}

.blob3 {
  width: 240px;
  height: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #acfa70;
  animation: ai-blob 3.5s linear infinite;
  z-index:1;
}

@keyframes ai-blob {
  0% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
  20% { border-radius: 70% 50% 40% 60% / 60% 25% 75% 40%; }
  40% { border-radius: 70% 30% 55% 35% / 80% 45% 55% 20%; }
  60% { border-radius: 30% 70% 70% 30% / 20% 30% 70% 80%; }
  80% { border-radius: 40% 65% 30% 62% / 70% 55% 45% 30%; }
  100% { border-radius: 30% 70% 76% 35% / 30% 30% 70% 70%; }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
  .stepContent {
    padding: 20px;
  }
</style>