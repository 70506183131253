<template>
  <div class="tw-max-w-6xl tw-mx-auto tw-p-6 tw-bg-white tw-shadow-md tw-rounded-xl tw-mt-10">
    <h1 class="tw-text-3xl tw-font-bold tw-mb-8">
      Trade Account Application
    </h1>

    <form
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6"
        @submit.prevent="handleSubmit"
    >
      <div
          v-for="field in fields"
          :key="field.id"
          class="tw-flex tw-flex-col"
      >
        <label
            v-if="field.type !== 'spacer'"
            :for="field.id"
            class="tw-mb-2 tw-font-medium"
        >
          {{ field.field_name }}
        </label>

        <!-- Text / Password Inputs -->
        <input
            v-if="field.type === 'text' || field.type === 'password'"
            :id="field.id"
            v-model="formData[field.id]"
            :type="field.type"
            class="tw-border tw-border-gray-300 tw-rounded tw-px-3 tw-py-2 tw-outline-none focus:tw-border-blue-500"
        >

        <!-- Select -->
        <select
            v-else-if="field.type === 'select'"
            :id="field.id"
            v-model="formData[field.id]"
            class="tw-border tw-border-gray-300 tw-rounded tw-px-3 tw-py-2 tw-outline-none focus:tw-border-blue-500"
        >
          <option
              disabled
              value=""
          >
            Please select
          </option>
          <option
              v-for="(option, index) in field.options"
              :key="index"
              :value="option.option"
          >
            {{ option.option }}
          </option>
        </select>

        <!-- Multi Select -->
        <multiselect
            v-else-if="field.type === 'multi-select'"
            v-model="formData[field.id]"
            :options="field.options.map(o => o.option)"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select options"
            class="tw-border tw-border-gray-300 tw-rounded focus:tw-border-blue-500"
        />

        <!-- Spacer -->
        <div v-else-if="field.type === 'spacer'"/>

        <!-- Error message -->
        <span
            v-if="errors.includes(field.id)"
            class="tw-text-red-500 tw-text-sm tw-mt-1"
        >This field is required</span>
      </div>

      <!-- Submit button -->
      <div class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-justify-end tw-mt-4">
        <button
            type="submit"
            class="tw-bg-blue-600 tw-text-white tw-py-2 tw-px-6 tw-rounded tw-w-full tw-font-medium hover:tw-bg-blue-700"
            style="background: #275EFE; color: white;"
            :style="{
              background: coreStore.branding.branding_colour
            }"
        >
          Submit
        </button>
      </div>

      <!-- Success message -->
      <div
          v-if="submitted"
          class="tw-col-span-1 md:tw-col-span-2"
      >
        <p class="tw-text-green-600 tw-font-semibold">
          Form submitted successfully ✅
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import {useToast} from 'vue-toastification'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {useCoreStore} from "@/stores/CoreStore";

export default {
  name: 'TradeAccountForm',
  components: {
    Multiselect
  },
  setup() {
    const coreStore = useCoreStore();
    return { coreStore };
  },
  data() {
    return {
      fields: [],
      formData: {},
      errors: [],
      submitted: false,
      toast: null
    }
  },
  mounted() {
    this.toast = useToast()
    this.fetchFields()
  },
  methods: {
    async fetchFields() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/trade/form/config`)
        this.fields = response.data
        this.formData = {}
        this.fields.forEach(field => {
          this.formData[field.id] = field.type === 'multi-select' ? [] : ''
        })
      } catch (e) {
        console.error('Error fetching fields:', e)
      }
    },

    validateForm() {
      this.errors = []
      this.fields.forEach(field => {
        if (field.type === 'spacer') return
        const value = this.formData[field.id]
        const isEmpty = Array.isArray(value) ? value.length === 0 : !value
        if (isEmpty) {
          this.errors.push(field.id)
        }
      })
      return this.errors.length === 0
    },

    async handleSubmit() {
      this.submitted = false

      if (!this.validateForm()) return

      const password = this.formData['password']
      const passwordConfirm = this.formData['password_confirm']

      if (password !== passwordConfirm) {
        this.toast.error('Passwords do not match ❌')
        return
      }

      try {
        await axios.post(
            `${process.env.VUE_APP_API_URL}/sqspace/register?domain=${window.location.hostname}&trade=1`,
            this.formData
        )
        this.submitted = true
        this.toast.success('Form submitted successfully ✅')
      } catch (e) {
        const errorMsg = e.response?.status === 422 && e.response.data?.message
            ? e.response.data.message
            : 'Something went wrong. Please try again.'
        this.toast.error(errorMsg)
        console.error('Submission failed:', e)
      }
    }
  }
}
</script>

