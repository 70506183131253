<template>
  <div class="tw-p-6 tw-bg-gray-100 tw-rounded-lg tw-shadow-inner">
    <h1 class="tw-text-2xl tw-font-semibold tw-mb-1 tw-text-gray-800">General Overview</h1>
    <nav class="tw-text-sm tw-text-gray-500 tw-mb-6">
      <span>Home</span> &gt; <span>My Account</span> &gt; <span class="tw-font-medium tw-text-gray-700">Overview</span>
    </nav>

    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-6">
      <div class="lg:tw-col-span-2">
        <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md tw-mb-6">
          <h2 class="tw-text-xl tw-font-semibold tw-mb-4 tw-text-gray-700">Account Data</h2>
          <div class="tw-flex tw-items-center tw-mb-4">
            <img :src="'https://placehold.co/40x40/E0E7FF/4F46E5?text=' + coreStore.auth.user.first_name[0] + coreStore.auth.user.last_name[0] " alt="User Avatar" class="tw-w-16 tw-h-16 tw-rounded-full tw-mr-4">
            <div>
              <h3 class="tw-text-lg tw-font-medium tw-text-gray-800">{{ coreStore.auth.user.first_name }} {{ coreStore.auth.user.last_name }}</h3>
              <p class="tw-text-sm tw-text-gray-600">{{ coreStore.auth.user.email }}</p>
              <p class="tw-text-sm tw-text-gray-600">{{ coreStore.auth.user.telephone }}</p>
            </div>
          </div>
          <button @click="editData" class="tw-mt-4 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-white tw-text-sm tw-font-medium tw-rounded-md hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-shadow">
            ✏️ Edit your data
          </button>
        </div>

        <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
          <h2 class="tw-text-xl tw-font-semibold tw-mb-4 tw-text-gray-700">Recent Orders</h2>
          <div class="tw-overflow-x-auto">
            <table class="tw-min-w-full tw-divide-y tw-divide-gray-200">
              <thead class="tw-bg-gray-50">
              <tr>
                <th scope="col" class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Order ID</th>
                <th scope="col" class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Date</th>
                <th scope="col" class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Price</th>
<!--                <th scope="col" class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Status</th>-->
                <th scope="col" class="tw-px-4 tw-py-3 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Actions</th>
              </tr>
              </thead>
              <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">

              <template v-if="accountStore.loading">
                <tr class="hover:tw-bg-gray-50">
                  <td colspan="5" class="tw-px-4 tw-py-4 tw-text-center tw-text-sm tw-text-gray-500">
                    Loading
                  </td>
                </tr>
              </template>
              <tr v-else-if="recentOrders.length === 0">
                <td colspan="5" class="tw-px-4 tw-py-4 tw-text-center tw-text-sm tw-text-gray-500">No recent orders found.</td>
              </tr>
              <template v-else>
              <tr v-for="order in recentOrders" :key="order.id" class="hover:tw-bg-gray-50">
                <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">#{{ order.id }}</td>
                <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">{{ formatDate(order.created_at) }}</td>
                <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">£{{ order.order_value.toLocaleString('') }}</td>
<!--                <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap">-->
<!--                     <span :class="getStatusClass('unknown')" class="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full">-->
<!--                       unknown-->
<!--                     </span>-->
<!--                </td>-->
                <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">
                  <button @click="viewOrder(order.id)" class="tw-text-blue-600 hover:tw-text-blue-900">View</button>
                </td>
              </tr>
              </template>
              </tbody>
            </table>
          </div>
          <button @click="goToOrders" class="tw-mt-4 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-bg-gray-600 tw-text-white tw-text-sm tw-font-medium tw-rounded-md hover:tw-bg-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-500 tw-shadow">
            See all orders
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAccountStore } from '@/stores/AccountStore';
import {useCoreStore} from "@/stores/CoreStore";
export default {
  name: 'AccountOverview',
  setup() {
    const coreStore = useCoreStore();
    const accountStore = useAccountStore();

    return {coreStore, accountStore};
  },
  computed: {
    // Map state and getters from the Pinia store
    ...mapState(useAccountStore, ['getRecentOrders']),
    ...mapState(useCoreStore, ['auth.user']),
    // Use the getter for recent orders
    recentOrders() {
      return this.getRecentOrders;
    }
  },
  methods: {
    // Map actions from the Pinia store
    ...mapActions(useAccountStore, ['setActivePage']),

    /**
     * Formats a date string into a more readable format.
     * @param {string} dateString - The date string (e.g., 'YYYY-MM-DD').
     * @returns {string} - Formatted date (e.g., 'Jan 27, 2025').
     */
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    /**
     * Determines the Tailwind CSS classes for the order status badge.
     * @param {string} status - The order status text.
     * @returns {string} - Tailwind classes for styling the badge.
     */
    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case 'in transit':
          return 'tw-bg-yellow-100 tw-text-yellow-800';
        case 'pre-order':
          return 'tw-bg-blue-100 tw-text-blue-800';
        case 'delivered': // Changed from 'confirmed' to 'delivered' based on store data
          return 'tw-bg-green-100 tw-text-green-800';
        case 'cancelled':
          return 'tw-bg-red-100 tw-text-red-800';
        default:
          return 'tw-bg-gray-100 tw-text-gray-800';
      }
    },

    /**
     * Navigates to the full orders page.
     */
    goToOrders() {
      this.setActivePage('AccountOrders');
    },

    /**
     * Placeholder for viewing a specific order's details.
     * @param {string} orderId - The ID of the order to view.
     */
    viewOrder(orderId) {
      console.log('View order:', orderId);
      // In a real app, you might navigate to an order detail page
      // or show a modal with order details.
      alert(`Viewing details for order #${orderId} (mock)`);
      this.setActivePage('AccountOrders'); // Navigate to orders page for now
    },
    /**
     * Placeholder for initiating the edit user data flow.
     */
    editData() {
      console.log('Edit user data clicked');

    }
  }
};
</script>

<style scoped>
/* Add specific styles if Tailwind isn't sufficient */
</style>
