<template>
  <div class="tw tw-min-h-screen tw-bg-gray-100 tw-flex tw-justify-center tw-items-center ">
    <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-lg tw-w-full tw-max-w-4xl">
      <h2 class="tw-text-2xl tw-font-bold tw-mb-4 tw-text-gray-900">
        EDO AI Proof Of Concept v0.1
      </h2>

      <ol class="tw-flex tw-items-center tw-w-full tw-text-xs tw-text-gray-900 tw-font-medium sm:tw-text-base">
        <li
            class="tw-flex tw-w-full tw-relative tw-text-indigo-600 after:tw-content-[''] after:tw-w-full after:tw-h-0.5 after:tw-bg-indigo-600 after:tw-inline-block after:tw-absolute lg:after:tw-top-5 after:tw-top-3 after:tw-left-4"
            :class="{ 'tw-text-indigo-600': currentStep === 1, 'tw-text-gray-900': currentStep !== 1 }"
        >
          <div class="tw-block tw-whitespace-nowrap tw-z-10">
            <span
                class="tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-mx-auto tw-mb-3 tw-text-sm lg:tw-w-10 lg:tw-h-10"
                :class="{ 'tw-bg-indigo-600 tw-border-2 tw-border-transparent tw-text-white': currentStep === 1, 'tw-bg-indigo-50 tw-border-2 tw-border-indigo-600 tw-text-indigo-600': currentStep !== 1 }"
            >1</span>
            Upload Image
          </div>
        </li>
        <li
            class="tw-flex tw-w-full tw-relative tw-text-gray-900 after:tw-content-[''] after:tw-w-full after:tw-h-0.5 after:tw-inline-block after:tw-absolute lg:after:tw-top-5 after:tw-top-3 after:tw-left-4"
            :class="{ 'after:tw-bg-indigo-600 tw-text-indigo-600': currentStep >= 2, 'after:tw-bg-gray-200': currentStep < 2 }"
        >
          <div class="tw-block tw-whitespace-nowrap tw-z-10">
            <span
                class="tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-mx-auto tw-mb-3 tw-text-sm lg:tw-w-10 lg:tw-h-10"
                :class="{ 'tw-bg-indigo-600 tw-border-2 tw-border-transparent tw-text-white': currentStep === 2, 'tw-bg-indigo-50 tw-border-2 tw-border-indigo-600 tw-text-indigo-600': currentStep > 2, 'tw-bg-gray-50 tw-border-2 tw-border-gray-200': currentStep < 2 }"
            >2</span>
            Edit Image
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiAssistPage",
  data() {
    return {
      currentStep: 1,
    };
  },
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
