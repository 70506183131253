import { createApp } from 'vue'
import { registerPageComponents } from './registerBackendComponents';
import { createPinia } from 'pinia';
import App from './App.vue'
import Toast from "vue-toastification";
import Vue3ConfirmDialog from '@zapadale/vue3-confirm-dialog'
import vueDebounce from 'vue-debounce'
import VueMobileDetection from "vue-mobile-detection";
import PrimeVue from 'primevue/config';
import 'vue-toastification/dist/index.css'

// Services
import DesignerService from '@/services/DesignerService'
import QuoteBuilderService from '@/services/QuoteBuilderService';
import CoreService from '@/services/CoreService'

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import 'primeicons/primeicons.css'
import '@/styles.scss';



console.log("Backend Master Script Loading....")

try {
    if (backendConfig !== undefined) {
        let app = createApp(App);
        app.directive('debounce', vueDebounce({lock: true}))

        // Check if backend location div already exists
        let locationDiv = document.querySelector(`#backend-app`);
        let options = {};

        app.use(createPinia());
        app.use(Toast, options);
        app.use(Vue3ConfirmDialog)
        app.use(VueMobileDetection)
        app.use(PrimeVue, {
            theme: 'none'
        });

        let coreService = new CoreService(backendConfig)
        let quoteBuilderService = new QuoteBuilderService(backendConfig.pageData);
        let designerService = new DesignerService(backendConfig.pageData)


        app.provide('page', 'pg-' + backendConfig.page)
        app.provide('settings', backendConfig.pageData)
        app.provide('quoteBuilderService', quoteBuilderService)
        app.provide('designerService', designerService)
        app.provide('coreService', coreService)

        if (backendConfig.page == 'quoting') {
            quoteBuilderService.init()
        }

        // If the backend location div doesn't exist, create a new one
        if (!locationDiv) {
            locationDiv = document.createElement('div');
            locationDiv.id = `backend-app`;
            document.body.appendChild(locationDiv);
        }

        registerPageComponents(app)
        app.mount(`#backend-app`);

    } else {
        console.log("Loading Finished")
    }
} catch (e) {
    console.log("Script Finished")
}

