<template>
  <loading
      v-model:active="designerStore.variantLoading"
      :can-cancel="false"
      :is-full-page="true"
  />

  <div id="main-container" class="tw-pb-[100px]">
    <div id="designer-border">
      <div id="designer-container-main">
        <div id="added-to-cart-overlay" class="tw-z-[1001]"></div>

        <transition-group name="fade">
          <Fader
              v-if="faderActive"
              :fader-step="faderStep"
              @select-product="selectProductId"
              @select-variant="selectVariant"
          />
          <template v-else>
            <Toolbar />
            <div id="designer-div"
                 class="tw-mx-auto tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 fader-slide fader-slide--2"
                 style="margin-top: 2%;"
            >

              <div
                  class="tw-relative tw-product-display"
                  id="image-container"
              >
                <button id="reopenHelp"
                        class="tw-bg-primary-600 hover:tw-bg-primary-500 tw-block tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-ml-2 tw-rounded"
                        style="z-index: 100000; display: none; position: absolute;"
                >
                  ?
                </button>

                <div id="help-container">
                  <p id="help-container-text"></p>
                  <button
                      class="tw-w-full tw-bg-primary-600 hover:tw-bg-primary-500 tw-block tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-mt-3 tw-rounded">
                    Close Help Article
                  </button>
                </div>

                <ImageLayering />

                <div
                    class="tw-block md:tw-hidden"
                    style="z-index: 100; background: #eaeaea; padding: 10px; position: absolute; top: 90%; left: 0; width: 100%; text-align: center;"
                >
                  <p class="tw-text-[20px] tw-font-bold">
                    £<span class="tw-current-price">{{designerStore.pricing.price}}</span> Inc VAT
                  </p>
                </div>
              </div>

              <div
                  id="steps-content"
                  class="tw-w-full tw-p-4 tw-text-center tw-bg-white content-min-height"
              >
                <div
                    id="steps-content-container"
                    class="tw-relative tw-overflow-y-scroll"
                >
                  <LayerSelectCard
                      @next-tab="nextTab"
                      @previous-tab="previousTab"
                      @layer-update="layerUpdate"
                  />
                </div>
              </div>
            </div>
          </template>
        </transition-group>
      </div>
      <div>
        <a href="mailto:info@bespokesoftwaresolutionsltd.co.uk" class="tw-float-right tw-p-5 tw-text-gray-500">
          Powered By Bespoke Software Solutions
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from "@/stores/DesignerStore";
import {inject} from "vue";
import Loading from "vue-loading-overlay";
import Fader from './Stepper/Fader.vue'
import Toolbar from './Stepper/Toolbar.vue'
import ImageLayering from './List/ImageLayering.vue'
import LayerSelectCard from './Stepper/LayerSelectCard.vue'

export default {
  name: "StepperLayout",
  components: {
    Loading,
    Fader,
    Toolbar,
    ImageLayering,
    LayerSelectCard,
  },
  setup() {
    const designerStore = useDesignerStore();
    return {designerStore};
  },
  data() {
    return {
      designerService: {},
      faderStep: 0,
      faderActive: true
    }
  },
  mounted() {
    let designerService = inject('designerService')
    this.designerService = designerService;

    this.faderStep = this.designerStore.product.linkedProducts ? 0 : 1;
    designerService.getProduct();
  },
  methods: {
    async selectProductId(productId) {
      await this.designerService.getProduct(productId)
      this.faderStep++;
    },
    async selectVariant(variantId) {
      this.designerStore.setVariant(variantId)
      await this.designerService.loadVariant()
      this.faderActive = false
    },
    layerUpdate(layerId) {
      this.designerService.runUpdates?.(layerId);
    },
    nextTab() {
      this.designerStore.nextTab()
      this.designerService.runUpdates?.();
    },
    previousTab() {
      this.designerStore.previousTab()
      this.designerService.runUpdates?.();
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .tw-add-cart-overlay {
    min-width: 80vw;
  }
}
</style>
