<template>
  <div class="tw-flex tw-flex-wrap tw-mb-4">
    <input
        type="text"
        placeholder="Key"
        v-model="item.key"
        class="tw-p-2 tw-border tw-rounded tw-mr-2 tw-w-1/6"
    >
    <input
        type="text"
        placeholder="Value"
        v-model="item.value"
        class="tw-p-2 tw-border tw-rounded tw-mr-2 tw-w-1/6"
    >
    <input
        type="number"
        placeholder="Price"
        v-model="item.price"
        prefix="£"
        class="tw-p-2 tw-border tw-rounded tw-mr-2 tw-w-1/6"
    >

    <label class="tw-inline-flex tw-items-center tw-mt-3 tw-w-1/6">
      <input
          type="checkbox"
          class="tw-form-checkbox tw-h-5 tw-w-5 tw-text-blue-600"
          v-model="item.visible"
      ><span class="tw-ml-2 tw-text-gray-700">Visible In Quote</span>
    </label>

    <button
        type="button"
        class="tw-ml-2 tw-bg-red-500 hover:tw-bg-red-400 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-w-1/6"
        @click="$emit('remove', _key)"
    >
      Remove
    </button>
  </div>
</template>

<script>
export default {
  props: ['_key', 'item'],
  emits: ['remove']
};
</script>
