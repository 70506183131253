<template>
  <div v-if="coreStore.coreLoading">
    <loading
        v-model:active="coreStore.coreLoading"
        :can-cancel="false"
        :is-full-page="true"
    />
  </div>
  <div
      v-else
      class="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gray-100"
  >
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden tw-w-full"
         style="margin: 80px !important;">

      <div class="tw-w-full md:tw-w-1/2 tw-p-8 tw-bg-gray-800 tw-text-white">
        <h2 class="tw-text-2xl tw-font-bold tw-mb-6">Welcome back</h2>

        <form>
          <div class="tw-mb-4">
            <label for="email" class="tw-block tw-text-sm tw-font-medium tw-text-gray-300 tw-mb-1">Email</label>
            <input
                type="email"
                id="email"
                name="email"
                v-model="email"
                placeholder="Enter your email"
                required
                class="tw-w-full tw-px-3 tw-py-2 tw-bg-gray-700 tw-border tw-border-gray-600 tw-rounded-lg tw-text-white placeholder-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-transparent"
            />
          </div>
          <div class="tw-mb-4">
            <label for="password" class="tw-block tw-text-sm tw-font-medium tw-text-gray-300 tw-mb-1">Password</label>
            <input
                type="password"
                id="password"
                name="password"
                v-model="password"
                placeholder="••••••••"
                required
                class="tw-w-full tw-px-3 tw-py-2 tw-bg-gray-700 tw-border tw-border-gray-600 tw-rounded-lg tw-text-white placeholder-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-transparent"
            />
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
            <a href="#" @click.prevent="handleForgotPassword" class="tw-text-sm tw-text-blue-500 hover:tw-underline">Forgot
              password?</a>
          </div>
          <button
              type="submit"
              class="tw-w-full tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded-lg tw-transition-colors"
              :style="{
                backgroundColor: coreStore.branding.branding_colour
              }"
              @click.prevent="handleLogin"
          >
            Sign in to your account
          </button>
        </form>

        <p class="tw-mt-8 tw-text-center tw-text-sm tw-text-gray-400">
          Don't have an account?
            <a v-if="coreStore.branding.trade_site" href="#" @click.prevent="coreService.changePage('trade-application')" class="tw-font-medium tw-text-blue-500 hover:tw-underline">
              Apply For A Trade Account
            </a>
          <a v-else href="#" @click.prevent="handleSignUp" class="tw-font-medium tw-text-blue-500 hover:tw-underline">
            Sign up
          </a>
        </p>
      </div>

      <div
          class="tw-w-full md:tw-w-1/2 tw-p-8 tw-text-white tw-hidden md:tw-flex tw-flex-col tw-justify-center"
          :style="{
            backgroundColor: coreStore.branding.branding_colour
          }"
      >
        <div
            v-if="coreStore.branding.logo_url"
            class="tw-mb-1 tw-flex tw-justify-center"
        >
          <img
              :src="coreStore.branding.logo_url"
              alt="Login page illustration"
              class="tw-max-w-full tw-h-auto tw-max-h-80 tw-rounded-lg-object-contain"
              style="max-height: 100px !important;"
          >
        </div>
        <div
            v-else
            class="tw-mb-6 tw-flex tw-items-center tw-gap-2"
        >
          <span class="tw-text-2xl tw-font-bold">{{ coreStore.branding.name }}</span>
        </div>
        <h1 class="tw-text-3xl tw-font-bold tw-mb-4">{{ coreStore.branding.login_header }}</h1>
        <p class="tw-mb-6 tw-text-blue-100">
          {{ coreStore.branding.login_text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// Script section remains unchanged
import {useCoreStore} from "@/stores/CoreStore";
import Loading from "vue-loading-overlay";
import {useToast} from "vue-toastification";

export default {
  name: 'LoginPage',
  components: {
    Loading,
  },
  inject: ['coreService'],
  setup() {
    const coreStore = useCoreStore();
    return {coreStore};
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
    };
  },
  methods: {
    async handleLogin() {
      const toast = useToast();
      if (!this.email || !this.password) {
        toast('Please enter both email and password,')
        return;
      }

      let login = await this.coreService.login({
        email: this.email,
        password: this.password
      })

      if (!login) {
        toast('Login Failed, please check your credentials are correct')
        return false;
      }
      toast('Login Success')

      if (this.coreStore.pageData.redirect) {
        this.coreService.changePage(this.coreStore.pageData.redirect)
      } else {
        this.coreService.changePage('account')
      }
    },
    handleForgotPassword() {
      console.log("forgot");
      // Add forgot password logic
    },
    handleSignUp() {
      console.log("signup");
      // Add sign up logic or navigation
    },
  },
};
</script>

<style scoped>
/* Styles remain the same */
/* Add any component-specific styles here if needed, */
/* although Tailwind should handle most styling. */
/* Ensure your project is configured to use Tailwind with the 'tw-' prefix. */
</style>