<template>
  <div class="tw-p-6 tw-bg-gray-100 tw-rounded-lg tw-shadow-inner">
    <h1 class="tw-text-2xl tw-font-semibold tw-mb-1 tw-text-gray-800">
      Address Book
    </h1>
    <nav class="tw-text-sm tw-text-gray-500 tw-mb-6">
      <span>Home</span> &gt; <span>My Account</span> &gt; <span
        class="tw-font-medium tw-text-gray-700">Address Book</span>
    </nav>

    <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 class="tw-text-xl tw-font-semibold tw-text-gray-700">
          Saved Addresses
        </h2>
        <button
            class="tw-px-4 tw-py-2 tw-bg-green-600 tw-text-white tw-text-sm tw-font-medium tw-rounded-md hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-green-500 tw-shadow"
            @click="showAddAddressForm = true"
        >
          + Add New Address
        </button>
      </div>

      <div
          v-if="showAddAddressForm"
          class="tw-mb-6 tw-p-6 tw-border tw-border-gray-200 tw-rounded-lg tw-bg-gray-50"
      >
        <h3 class="tw-text-xl tw-font-semibold tw-mb-4 tw-text-gray-800">
          {{ isEditing ? 'Edit Address' : 'Add New Address' }}
        </h3>

        <form
            class="tw-space-y-5 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4"
            @submit.prevent="submitNewAddress"
        >
          <div style="margin-top: 21px;">
            <label
                for="address_line_one"
                class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1"
            >Address Line 1</label>
            <input
                id="address_line_one"
                v-model="newAddress.address_line_one"
                type="text"
                required
                class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200"
            >
          </div>

          <div>
            <label
                for="address_line_two"
                class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1"
            >Address Line 2</label>
            <input
                id="address_line_two"
                v-model="newAddress.address_line_two"
                type="text"
                class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200"
            >
          </div>

          <div>
            <label
                for="address_line_three"
                class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1"
            >Address Line 3</label>
            <input
                id="address_line_three"
                v-model="newAddress.address_line_three"
                type="text"
                class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200"
            >
          </div>

          <div>
            <label
                for="address_city"
                class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1"
            >City</label>
            <input
                id="address_city"
                v-model="newAddress.address_city"
                type="text"
                required
                class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200"
            >
          </div>

          <div>
            <label
                for="address_county"
                class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1"
            >County</label>
            <input
                id="address_county"
                v-model="newAddress.address_county"
                type="text"
                class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200"
            >
          </div>

          <div>
            <label
                for="address_postcode"
                class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1"
            >Postcode</label>
            <input
                id="address_postcode"
                v-model="newAddress.address_postcode"
                type="text"
                required
                class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200"
            >
          </div>

          <div class="tw-flex tw-justify-end tw-space-x-3 tw-col-span-2">
            <button
                type="button"
                class="tw-px-6 tw-py-2.5 tw-bg-gray-300 tw-text-gray-800 tw-font-medium tw-rounded-md hover:tw-bg-gray-400"
                @click="resetForm(); showAddAddressForm = false"
            >
              Cancel
            </button>
            <button
                type="submit"
                class="tw-px-6 tw-py-2.5 tw-bg-blue-600 tw-text-white tw-font-medium tw-rounded-md hover:tw-bg-blue-700"
            >
              {{ isEditing ? 'Update Address' : 'Save Address' }}
            </button>
          </div>
        </form>
      </div>


      <div
          v-if="addresses.length === 0 && !showAddAddressForm"
          class="tw-text-center tw-py-6 tw-text-gray-500"
      >
        You haven't saved any addresses yet.
      </div>
      <div
          v-else
          class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4"
      >
        <div
            v-for="address in addresses"
            :key="address.id"
            class="tw-border tw-border-gray-200 tw-p-4 tw-rounded-md tw-relative"
        >
          <h3 class="tw-font-semibold tw-text-lg tw-mb-1 tw-text-gray-800">
            {{ address.address_line_one }}
          </h3>
          <p class="tw-text-sm tw-text-gray-600">
            {{ address.address_line_two }}
          </p>
          <p class="tw-text-sm tw-text-gray-600">
            {{ address.address_city }}, {{ address.address_county }} {{ address.address_postcode }}
          </p>
          <p class="tw-text-sm tw-text-gray-600">
            United Kingdom
          </p>
          <div class="tw-mt-3 tw-pt-3 tw-border-t tw-border-gray-100 tw-flex tw-space-x-2">
            <button
                class="tw-text-blue-600 hover:tw-text-blue-900 tw-text-sm"
                @click="editAddress(address.id)"
            >
              Edit
            </button>
            <!--            <button @click="confirmDeleteAddress(address.id)" class="tw-text-red-600 hover:tw-text-red-900 tw-text-sm">Delete</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'pinia';
import {useAccountStore} from '@/stores/AccountStore';
import {useToast} from "vue-toastification";

export default {
  name: 'AccountAddressBook',
  inject: ['coreService'],
  data() {
    return {
      showAddAddressForm: false,
      isEditing: false,

      // Form model
      newAddress: {
        id: null,
        address_line_one: '',
        address_line_two: '',
        address_line_three: '',
        address_city: '',
        address_county: '',
        address_postcode: ''
      }
    };
  },

  computed: {
    ...mapState(useAccountStore, ['getAddressBook']),

    addresses() {
      return this.getAddressBook;
    }
  },

  methods: {
    async submitNewAddress() {
      let addressUpdate
      const payload = {
        address_line_one: this.newAddress.address_line_one,
        address_line_two: this.newAddress.address_line_two,
        address_line_three: this.newAddress.address_line_three,
        address_city: this.newAddress.address_city,
        address_county: this.newAddress.address_county,
        address_postcode: this.newAddress.address_postcode
      };
      // Get toast interface
      const toast = useToast();

      // Use it!
      if (this.isEditing && this.newAddress.id !== null) {
        payload.id = this.newAddress.id;
        addressUpdate = await this.coreService.createOrUpdateAddress(payload)
      } else {
        addressUpdate = await this.coreService.createOrUpdateAddress(payload)
      }

      if (addressUpdate) {
        this.resetForm();
        this.showAddAddressForm = false;
        toast("Address Submitted Successfully");

      } else {
        this.resetForm();
        this.showAddAddressForm = false;
        toast("An Error Occurred please try again later");

      }


    },

    resetForm() {
      this.newAddress = {
        id: null,
        address_line_one: '',
        address_line_two: '',
        address_line_three: '',
        address_city: '',
        address_county: '',
        address_postcode: ''
      };
      this.isEditing = false;
    },

    editAddress(addressId) {
      const existing = this.addresses.find(a => a.id === addressId);
      if (existing) {
        this.newAddress = {...existing}; // Clone the existing address
        this.isEditing = true;
        this.showAddAddressForm = true;
      }
    },

    confirmDeleteAddress(addressId) {
      if (confirm('Are you sure you want to delete this address?')) {
        console.log('Mock DELETE address with ID:', addressId);
        // Fake remove in store here if desired
      }
    }
  }
};
</script>


<style scoped>
/* Scoped styles */
</style>
