<template>
  <div class="tailwind">
    <component
        :is="coreStore.page"
    />
  </div>

  <vue3-confirm-dialog />
</template>

<script>
import { inject } from 'vue';
import {useCoreStore} from "@/stores/CoreStore";

export default {
  name: 'App',
  setup() {
    const coreStore = useCoreStore();


    return { coreStore };
  }}
</script>

