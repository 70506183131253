<template>
  <div
      v-if="faderStep > 0"
      id="back-button"
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-2"
      style="padding: 20px 0;"
  >
    <div class="tw-col-span-3 tw-text-center">
      <button
          style="min-width: 150px"
          type="button"
          class="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-full tw-text-sm tw-p-2.5 tw-text-center tw-inline-flex tw-items-center me-2 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 dark:focus:tw-ring-blue-800"
      >
        <span class="tw-text-center">Back</span>
      </button>
    </div>
  </div>

  <div
      id="back-button-2"
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-2"
      style="padding: 20px 0;"
  >
    <div class="tw-col-span-3 tw-text-center">
      <h3
          v-if="faderStep == 0"
          id="selectorTitle"
          class="tw-text-[30px] tw-font-bold"
      >
        Select A Product Type
      </h3>
      <h3
          v-else-if="faderStep == 1"
          id="selectorTitle"
          class="tw-text-[30px] tw-font-bold"
      >
        Select A Variant
      </h3>
    </div>
  </div>

  <div
      id="selector-div"
      class="tw-fader-stage"
      style="padding: 20px 0; overflow: scroll; max-height: 79vh;"
  >
    <div
        class="tw-mx-auto tw-fader-slide tw-fader-slide--1"
        style="position: relative"
    >
      <!-- Product Image Display -->
      <div
          class="tw-product-display"
          style="position: relative"
      >
        <div
            id="fader-content"
            class="tw-grid tw-grid-cols-2 tw-gap-2"
        >
          <transition-group name="fade">
            <template v-if="faderStep == 0">
              <div
                  v-for="linkedProduct in designerStore.product.linkedProducts.links"
                  :key="linkedProduct.id"
                  class="tw-hoverable"
                  style="position: relative; cursor: pointer"
                  @click="selectProduct(linkedProduct.product_id)"
              >
                <img
                    :src="linkedProduct.image_url"
                    class="tw-centre-div"
                    style="max-height: 95%; border-radius: 10px"
                >
                <p
                    class="tw-centre-div-horz tw-selector-title"
                    style="width: 100%; text-align: center;"
                >
                  {{ linkedProduct.display_name }}
                </p>
              </div>
            </template>
            <template v-else-if="faderStep == 1">
              <div
                  v-for="variant in visibleOptions"
                  :key="variant.id"
                  class="tw-hoverable"
                  style="position: relative;"
                  @click="selectVariant(variant)"
              >
                <img
                    :src="variant.thumbnail_url"
                    class="tw-centre-div"
                    style="max-height: 95%; border-radius: 10px"
                >
                <p
                    class="tw-centre-div-horz tw-selector-title"
                    style="width: 100%; text-align: center;"
                >
                  {{ variant.name }}
                </p>
              </div>
            </template>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';

export default {
  name: "StepperFader",
  props: ['faderStep'],
  setup() {
    const designerStore = useDesignerStore();
    return {designerStore};
  },
  computed: {
    visibleOptions() {
      return Object.values(this.designerStore.product.variants).filter(option => option.hidden == 0);
    }
  },
  methods: {
    selectProduct(productId) {
      this.$emit('selectProduct', productId)
    },
    selectVariant(variantId) {
      this.$emit('selectVariant', variantId)
    }
  }
}
</script>

<style scoped>
.tw-hoverable {
  cursor: pointer;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 10px;
  padding-bottom: 10px;
  border: none;
  min-height: 400px;
  border: 1px solid #e4cccc;
}

.tw-hoverable:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}

.tw-centre-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tw-centre-div-horz {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.tw-selector-title {
  font-size: 20px;
  font-weight: bold;
}
</style>