<template>
  <div
      id="variant-selector"
      class="tw-pb-8"
      v-if="designerStore.product.linkedProducts.links.length > 0"
  >
    <div class="tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden">
      <!-- Header -->
      <h5
          class="tw-text-white tw-bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] tw-p-4"
          :style="{
            backgroundColor: designerStore.branding.branding_colour
          }"
      >
        {{ designerStore.product.linkedProducts.name }}
      </h5>
      <!-- Card body -->
      <div class="tw-p-4">
        <div
            class="tw-flex tw-flex-wrap"
        >
          <!-- Option -->
          <span
              v-for="linkedProduct in designerStore.product.linkedProducts.links"
              :key="linkedProduct.id"
              class="tw-px-2 tw-m-2"
              style="cursor:pointer;"
              :class="[
              'tw-p-2 tw-border tw-rounded-md tw-relative',
              designerStore.product.object.id === linkedProduct.product_id
                ? 'cc-active'
                : 'tw-border-gray-300'
            ]"
              @click="updateProduct(linkedProduct.product_id)"
          >
            <div style="width: 200px; height: 200px;">
              <img
                  :src="linkedProduct.image_url"
                  alt=""
                  class="tw-object-cover tw-rounded tw-absolute"
                  style="height: 200px; width: 100%; top: 0; right: 0"
              >
            </div>
            <p class="tw-text-center tw-text-sm">
              {{ linkedProduct.display_name }}
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';

export default {
  name: "LinkedProduct",
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  },
  methods: {
    updateProduct(productId) {
      this.$emit('productChanged', productId)
    }
  }
}
</script>

<style scoped>
/* Tailwind CSS is assumed to be included globally. */
</style>
