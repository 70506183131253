<template>
  <div class="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-75 tw-z-50 tw-py-5">
    <div class="tw-bg-white tw-w-1/2 tw-p-5 tw-m-auto tw-rounded">
      <button
          class="tw-bg-red-600 hover:tw-bg-red-500 tw-text-white tw-font-bold tw-py-1 tw-px-2 tw-rounded tw-absolute tw-top-0 tw-right-0 tw-m-2"
          @click="$emit('close')"
      >
        X
      </button>
      <h2 class="tw-font-semibold tw-text-xl tw-mb-4">
        Edit Quote Details
      </h2>
      <div>
        <label class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Email</label>
        <input
            v-model="email.email"
            type="text"
            class="tw-mb-4 tw-p-2 tw-w-full tw-border tw-rounded"
        >
        <label class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Message To Show On Quote</label>
        <textarea
            id="message"
            rows="4"
            class="tw-block tw-mb-4 tw-p-2.5 tw-w-full tw-text-sm tw-rounded-lg tw-border focus:tw-ring-blue-500 focus:tw-border-blue-500"
            placeholder=""
            v-model="email.message"
        ></textarea>

        <button
            class="tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-w-full"
            @click="sendEmail"
        >
          Send Email
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {useQuoteStore} from "@/stores/QuoteStore";
export default {
  props: ['quoteName'],
  data() {
    return {
      email: {
        email: "",
        message: ""
      }
    }
  },
  mounted() {
    let quoteStore = useQuoteStore();
    this.email.message = quoteStore.branding.default_quote_message;
    this.email.email = quoteStore.quoteObj.customer_email;
  },
  methods: {
    sendEmail() {
      const quoteStore = useQuoteStore();
      this.$emit('sendEmail', this.email);
      this.$emit('close');
    }
  }
};
</script>
