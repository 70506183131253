<template>
  <div class="">
<!--    <p>Need some help?</p>-->

    <!-- Contact Button -->
    <button
        class="tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-focus:outline-none"
        :style="{
            backgroundColor: designerStore.branding.branding_colour
          }"
        style="padding: 10px"
        @click="toggleForm"
    >
      <svg viewBox="0 0 512 512" width="100%" height="25"  fill="#fff" xmlns="http://www.w3.org/2000/svg" id="fi_1660114">
        <path d="m512 346.5c0-74.628906-50.285156-139.832031-121.195312-159.480469-4.457032-103.878906-90.347657-187.019531-195.304688-187.019531-107.800781 0-195.5 87.699219-195.5 195.5 0 35.132812 9.351562 69.339844 27.109375 99.371094l-26.390625 95.40625 95.410156-26.386719c27.605469 16.324219 58.746094 25.519531 90.886719 26.90625 19.644531 70.914063 84.851563 121.203125 159.484375 121.203125 29.789062 0 58.757812-7.933594 84.210938-23.007812l80.566406 22.285156-22.285156-80.566406c15.074218-25.453126 23.007812-54.421876 23.007812-84.210938zm-411.136719-15.046875-57.117187 15.800781 15.800781-57.117187-3.601563-5.632813c-16.972656-26.554687-25.945312-57.332031-25.945312-89.003906 0-91.257812 74.242188-165.5 165.5-165.5s165.5 74.242188 165.5 165.5-74.242188 165.5-165.5 165.5c-31.671875 0-62.445312-8.972656-89.003906-25.945312zm367.390625 136.800781-42.382812-11.726562-5.660156 3.683594c-21.941407 14.253906-47.433594 21.789062-73.710938 21.789062-58.65625 0-110.199219-37.925781-128.460938-92.308594 89.820313-10.355468 161.296876-81.832031 171.65625-171.65625 54.378907 18.265625 92.304688 69.808594 92.304688 128.464844 0 26.277344-7.535156 51.769531-21.789062 73.710938l-3.683594 5.660156zm0 0"></path><path d="m180.5 271h30v30h-30zm0 0"></path><path d="m225.5 150c0 8.519531-3.46875 16.382812-9.765625 22.144531l-35.234375 32.25v36.605469h30v-23.394531l25.488281-23.328125c12.398438-11.347656 19.511719-27.484375 19.511719-44.277344 0-33.085938-26.914062-60-60-60s-60 26.914062-60 60h30c0-16.542969 13.457031-30 30-30s30 13.457031 30 30zm0 0"></path>
      </svg>
      <span style="color: #fff">Get Help</span>

    </button>

    <!-- Contact Form -->
    <transition name="fade">
      <div v-if="showForm" class="tw-mt-2 tw-p-4 tw-border tw-rounded tw-shadow-lg tw-bg-white callback-box">
        <h4>Request A Callback</h4>
        <small>Need some help and want to talk to one of our advisors? Simply fill in the form below and we will be in touch shortly</small>
        <!-- Loading State -->
        <div v-if="isSubmitting" class="tw-text-gray-600">
          Sending...
        </div>
        <!-- Form Fields -->
        <div v-else>
          <label class="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Name:</label>
          <input v-model="name" type="text" class="tw-w-full tw-p-2 tw-border tw-rounded" />

          <label class="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1 mt-2">Phone Number:</label>
          <input v-model="phone" type="text" class="tw-w-full tw-p-2 tw-border tw-rounded" />

          <label class="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1 mt-2">Email:</label>
          <input v-model="email" type="email" class="tw-w-full tw-p-2 tw-border tw-rounded" />

          <button
              class="mt-3"
              :style="{
                backgroundColor: designerStore.branding.branding_colour
              }"

              style="color: white; padding: 10px;border-radius: 10px; margin: 5px"
              @click="submitForm"
          >
            Submit
          </button>
          <br />
          <small>By submitting this form, I agree that my data will be processed for this request in accordance with our <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</small>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useDesignerStore } from '@/stores/DesignerStore';

export default {
  name: 'CallbackRequest',
  data() {
    return {
      designerStore: useDesignerStore(),
      showForm: false,
      isSubmitting: false,
      name: '',
      phone: '',
      email: ''
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    async submitForm() {
      this.isSubmitting = true;

      try {
        await this.$emit('callback', { name: this.name, phone: this.phone, email: this.email });
      } finally {
        this.isSubmitting = false;
        this.showForm = false;

        // Clear fields after submission
        this.name = '';
        this.phone = '';
        this.email = '';
      }
    }
  }
};
</script>


<style scoped>
/* Transition for the form */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}


.callback-box {
  width: 230px;
  margin-left: -100px;
  position: relative;
  z-index: 1000;
}
</style>
