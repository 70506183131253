import { defineStore } from 'pinia';

export const useCoreStore = defineStore('coreStore', {
    state: () => ({
        page: '',
        targetId: 0,
        branding: {},
        coreLoading: true,
        auth: {
            loading: true,
            authenticated: false,
            token: {},
            user: {}
        },
        cart: {
            loading: true,
            sessionId: '',
            cartItems: [],
            object: {},
            values: {}
        },
        pageData: {}
    }),
    getters: {

    },
    actions: {
        updateCartItemQuantity(payload) {
            const item = this.cart.cartItems.find((item) => item.id === payload.itemId);
            if (item) {
                item.quantity = payload.quantity;
            }
        },
    },
});
