import {useDesignerStore} from "@/stores/DesignerStore";
import axios from "axios";
import {useCoreStore} from "@/stores/CoreStore";

export default class QuoteBuilderService {
    constructor(pageData) {
        this.baseUrl = process.env.VUE_APP_API_URL
        this.pageData = pageData
        this.id = pageData.productId

        // Create Axios instance with base URL
        this.axios = axios.create({
            baseURL: this.baseUrl,
        });

        this.authoriseApi();
    }

    authoriseApi() {
        const coreStore = useCoreStore();
        if (coreStore.auth.token.token) {
            this.axios.interceptors.request.use(function (config) {
                let token = coreStore.auth.token.token;

                if (token !== null) {
                    config.headers.Authorization = 'Bearer ' + token;
                }

                return config;
            },function (error) {
                console.error(e)

                // Check if the error is due to an HTTP response status code
                if (error.response) {
                    // Check specifically for 401 Unauthorized
                    if (error.response.status === 401) {
                        coreStore.auth.authenticated = false;
                    }
                }
            },function (error) {
                console.error(e)

                // Check if the error is due to an HTTP response status code
                if (error.response) {
                    // Check specifically for 401 Unauthorized
                    if (error.response.status === 401) {
                        coreStore.auth.authenticated = false;
                    }
                }
            });
        }
    }

    async getProduct(id = null) {
        if (id !== null) this.id = id;
        let urlParams = new URLSearchParams(window.location.search);
        let share;

        if (urlParams.has('shid')) {
            share = await this.loadShareDetails(urlParams.get('shid'))
            this.id = share.data.product_id
        }

        const designerStore = useDesignerStore();
        let response = await this.axios.get('/api/designer/v2/product/' + this.id + '?domain=' + window.location.origin.replace(/(^\w+:|^)\/\//, ''));
        let variants = Object.values(response.data.variants)

        designerStore.setProduct(response.data.product)
        designerStore.setVariants(variants)
        designerStore.setBranding(response.data.branding)
        designerStore.setLinkedProducts(response.data.linked_products)

        designerStore.pricing.shippingPrice = response.data.shippingPrice;
        designerStore.pricing.shippingFreeMin = response.data.shippingThreshold;

        // This has a share id, so we need to grab the shared config from the server and use this
        if (urlParams.has('shid')) {
            let options = share.data.selected_options.selectedOptions;

            response.data.variants.forEach(function (item) {
                if (item.id == options.variant_id) {
                    designerStore.selectedVariant = item
                }
            })

            designerStore.setSelectedOptions(options)
            this.runUpdates()
        } else {
            designerStore.selectedVariant = variants[0];
            this.loadVariant()

        }

        designerStore.setLoading(false)

    }

    async loadShareDetails(shareId) {
        return await this.axios.get('/api/designer/v2/share/get/' + shareId)
    }

    async loadVariant() {
        const designerStore = useDesignerStore();
        designerStore.setImages([])
        designerStore.variantLoading = true;


        let response = await this.axios.get('/api/designer/v2/config/variant/' + designerStore.selectedVariant.id)
        designerStore.setSelectedOptions(response.data.data.default_values)
        designerStore.setTabs(response.data.data.categories)

        designerStore.selectedOptions.view = 'External';

        if (designerStore.designerMode == 1) {
            designerStore.activeTab = response.data.data.categories[0]
        }

        this.loadLayers()
        this.runUpdates()
    }

    async loadLayers() {
        const designerStore = useDesignerStore();

        let response = await this.axios.post('/api/designer/v2/variant/layers/' + designerStore.selectedVariant.id, {
            'layers': '' +  designerStore.activeTab,
            'selectedOptions':  designerStore.selectedOptions
        })

        if (designerStore.designerMode == 1) {
            designerStore.product.tabLayers = response.data.layers
            designerStore.product.activeLayerTab = Object.values(response.data.layers)[0]
        }

        designerStore.setDimensionFields(response.data.fields);
    }

    async getPrice() {
        const designerStore = useDesignerStore();

        let response = await this.axios.post('/api/designer/v2/variant/price/' + designerStore.selectedVariant.id, designerStore.selectedOptions)

        designerStore.setLayers(response.data.layers)
        designerStore.setPrice(response.data.price)
        designerStore.setSelectedOptions(response.data.options)
    }

    async loadImages() {
        const designerStore = useDesignerStore();

        let response = await this.axios.post('/api/designer/v2/variant/images/' + designerStore.selectedVariant.id, {
            'selectedOptions':  designerStore.selectedOptions
        })

        designerStore.setImages(response.data.images)
    }

    async loadHelpArticle(layerId) {
        const designerStore = useDesignerStore();

        if (layerId !== null) {
            let response = await this.axios.post('/api/designer/v2/variant/help/' + layerId, {
                'selectedOptions': designerStore.selectedOptions
            })

            designerStore.setHelp(response.data.article)
        } else {
            designerStore.setHelp(null)
        }

    }

    async generateShareLink() {
        const designerStore = useDesignerStore();
        designerStore.share.loading = true

        let response = await this.axios.post('/api/designer/v2/product/share/' + designerStore.product.object.id, {
            'selectedOptions': designerStore.selectedOptions
        })

        designerStore.share.token = response.data.token
        designerStore.share.loading = false

    }

    async generateCallback(callbackData) {
        const designerStore = useDesignerStore();

        let options = designerStore.selectedOptions

        let response = await this.axios.post('/api/designer/v2/product/share/' + designerStore.product.object.id, {
            'selectedOptions': options,
            'callback': true,
            'callback_name': callbackData.name,
            'callback_email': callbackData.email,
            'callback_phone': callbackData.phone,
        })

    }

    async runUpdates(layerId = null) {
        const designerStore = useDesignerStore();

        designerStore.variantLoading = true;

        await Promise.all([
            this.loadLayers(),
            this.getPrice(),
            this.loadHelpArticle(layerId)
        ]);

        // Images need to load last to ensure everything is correct
        this.loadImages();

        designerStore.variantLoading = false
    }

    async addToCart() {
        const designerStore = useDesignerStore();
        const coreStore = useCoreStore();

        let response = await this.axios.post(
            '/api/sqspace/cart?sessId=' + coreStore.cart.sessionId,
            designerStore.selectedOptions
        );

        const evt = new CustomEvent("itemAddedToCart", {detail: "some-data"});
        window.dispatchEvent(evt);

        if (response.status == 200) {
            designerStore.cartMessage = 'Item was successfully added to cart'
            designerStore.cartMessageType = 'add_cart'
            designerStore.addedToCart = true
        } else {
            designerStore.cartMessage = 'Unable to add to cart, please try again later or contact our support team'
            designerStore.cartMessageType = 'add_cart_fail'
        }

    }
}