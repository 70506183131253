import { defineStore } from 'pinia';

export const useDesignerStore = defineStore('designerStore', {
    state: () => ({
        designerMode: 0,
        loading: true,
        variantLoading: false,
        editing: false,
        itemId: null,
        renderView: "Exterior",
        pricing: {
            price: 0,
            shippingPrice: 0,
            shippingFreeMin: 0,
        },
        activeTab: "all",
        cartMessage: "",
        cartMessageType: "success",
        addedToCart: false,
        product: {
            object: {},
            variants: [],
            layers: [],
            dimensionFields: [],
            linkedProducts: [],
            tabs: {},
            tabLayers: {},
            activeLayerTab: {},
            completedTabs: []
        },
        share: {
            loading: true,
            token: ''
        },
        branding: {},
        helpArticle: '',
        selectedVariant: {},
        selectedOptions: {},
        images: {}
    }),
    getters: {
        getShareLink(state) {
            return window.location.protocol + '//' + window.location.host + window.location.pathname + '?shid=' + state.share.token
        }
    },
    actions: {
        /**
         * Moves to the next tab.
         * If Last tab then do nothing
         */
        nextTab() {
            // Save the current active tab if not already saved
            if (!this.product.completedTabs.includes(this.activeTab)) {
                this.product.completedTabs.push(this.activeTab)
            }

            // Determine the index of the current tab
            const currentIndex = this.product.tabs.indexOf(this.activeTab)
            const nextIndex = currentIndex + 1

            if (nextIndex < this.product.tabs.length) {
                // There is a next tab: update the active tab
                this.activeTab = this.product.tabs[nextIndex]
            }
        },
        /**
         * Moves to the previous tab.
         * Returns true if it successfully moved to a previous tab,
         * or false if the current tab is the first tab.
         */
        previousTab() {
            const currentIndex = this.product.tabs.indexOf(this.activeTab)
            if (currentIndex > 0) {
                // Move to the previous tab
                this.activeTab = this.product.tabs[currentIndex - 1]
                return true
            } else {
                // No previous tab exists
                return false
            }
        },
        swapView() {
            this.renderView = this.renderView === "Exterior" ? "Interior" : "Exterior";
        },
        setPrice(price) {
            this.pricing.price = price
        },
        incrementQty() {
            this.selectedOptions.qty++;
        },
        decrementQty() {
            if (this.selectedOptions.qty > 1) this.selectedOptions.qty--;
        },
        setSelectedOptions(options) {
            this.selectedOptions = options
            this.selectedOptions.variantId = this.selectedVariant.id
        },
        setLayers(layers) {
            this.product.layers = layers
        },
        setLinkedProducts(linkedProducts) {
            this.product.linkedProducts = linkedProducts
        },
        setDimensionFields(fields) {
            this.product.dimensionFields = fields
        },
        changeOption(layer, option) {
            this.selectedOptions[layer.id] = option.id
        },
        setProduct(product) {
          this.product.object = product
        },
        setVariants(variants) {
            this.product.variants = variants
        },
        setTabs(tabs) {
            this.product.tabs = tabs;
        },
        setImages(images) {
            this.images = images
        },
        setHelp(help) {
            this.helpArticle = help
        },
        setLoading(loading) {
            this.loading = loading
        },
        addToCart() {
            this.cartMessage = "Product added to cart.";
            setTimeout(() => {
                this.cartMessage = "";
            }, 3000);
        },
        setVariant(variant) {
            this.selectedOptions.variantId = variant.id;
            this.selectedVariant = variant
        },
        setBranding(branding) {
            this.branding = branding
        }
    },
});
