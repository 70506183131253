<template>
  <div class="tw-p-6 tw-bg-gray-100 tw-rounded-lg tw-shadow-inner">
    <h1 class="tw-text-2xl tw-font-semibold tw-mb-1 tw-text-gray-800">
      Aftersales
    </h1>
    <nav class="tw-text-sm tw-text-gray-500 tw-mb-6">
      <span>Home</span> &gt; <span>My Account</span> &gt; <span
        class="tw-font-medium tw-text-gray-700">Aftersales</span>
    </nav>

    <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 class="tw-text-xl tw-font-semibold tw-text-gray-700">Aftersales History</h2>
        <button
            class="tw-px-4 tw-py-2 tw-bg-orange-600 tw-text-white tw-text-sm tw-font-medium tw-rounded-md hover:tw-bg-orange-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-orange-500 tw-shadow"
            @click="showNewReturnForm = !showNewReturnForm"
        >
          Start a New Aftersales Request
        </button>
      </div>

      <!-- 💡 Form now starts on its own row -->
      <div v-if="showNewReturnForm" class="tw-mb-6 tw-bg-gray-50 tw-border tw-border-gray-200 tw-rounded-lg tw-p-6">
        <h3 class="tw-text-xl tw-font-semibold tw-mb-4 tw-text-gray-800">New Aftersales Request</h3>

        <form @submit.prevent="submitNewReturn" class="tw-space-y-5">
          <div>
            <label for="linked_order" class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1">Select Order</label>
            <select id="linked_order" v-model="newReturn.linked_order" required
                    class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200">
              <option disabled value="">-- Select an Order --</option>
              <option v-for="order in orders" :key="order.id" :value="order.id">
                #{{ order.id }} - {{ formatDate(order.created_at) }}
              </option>
            </select>
          </div>

          <div>
            <label for="issue_description" class="tw-block tw-text-base tw-font-medium tw-text-gray-700 mb-1">Issue Description</label>
            <textarea id="issue_description" v-model="newReturn.issue_description" rows="5" required
                      class="tw-w-full tw-p-3 tw-border tw-border-gray-300 tw-rounded-md tw-text-base focus:tw-border-indigo-500 focus:tw-ring-2 focus:tw-ring-indigo-200"
                      placeholder="Describe the issue with your order..."></textarea>
          </div>

          <div class="tw-flex tw-justify-end tw-space-x-3">
            <button type="button" @click="resetNewReturnForm"
                    class="tw-px-6 tw-py-2.5 tw-bg-gray-300 tw-text-gray-800 tw-font-medium tw-rounded-md hover:tw-bg-gray-400">
              Cancel
            </button>
            <button type="submit"
                    class="tw-px-6 tw-py-2.5 tw-bg-orange-600 tw-text-white tw-font-medium tw-rounded-md hover:tw-bg-orange-700">
              Submit Request
            </button>
          </div>
        </form>
      </div>


      <div class="tw-overflow-x-auto">
        <table class="tw-min-w-full tw-divide-y tw-divide-gray-200">
          <thead class="tw-bg-gray-50">
          <tr>
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Return ID
            </th>
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Order ID
            </th>
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Date Initiated
            </th>
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Status
            </th>
            <!--            <th scope="col" class="tw-px-4 tw-py-3 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Actions</th>-->
          </tr>
          </thead>
          <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
          <tr v-if="aftersalesRecords.length === 0">
            <td
                colspan="6"
                class="tw-px-4 tw-py-4 tw-text-center tw-text-sm tw-text-gray-500"
            >
              No return or issue history found.
            </td>
          </tr>
          <tr
              v-for="record in aftersalesRecords"
              :key="record.id"
              class="hover:tw-bg-gray-50"
          >
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
              #{{ record.id }}
            </td>
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
              #{{ record.linked_order }}
            </td>
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
              {{ formatDate(record.created_at) }}
            </td>
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap">
                 <span
                     :class="getStatusClass(record.status)"
                     class="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full"
                 >
                   {{ record.status }}
                 </span>
            </td>
            <!--            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">-->
            <!--              <button @click="viewReturnDetails(record.id)" class="tw-text-blue-600 hover:tw-text-blue-900">View Details</button>-->
            <!--            </td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'pinia';
import {useAccountStore} from '@/stores/AccountStore';
import {useToast} from "vue-toastification";

export default {
  name: 'AccountAftersales',
  inject: ['coreService'],
  computed: {
    // Map state from the Pinia store using the getter
    ...mapState(useAccountStore, ['getAftersales', 'orders']),
    // Alias the getter
    aftersalesRecords() {
      return this.getAftersales;
    }
  },
  data() {
    return {
      showNewReturnForm: false,
      newReturn: {
        linked_order: '',
        issue_description: ''
      }
    };
  },
  methods: {
    /**
     * Formats a date string.
     */
    formatDate(dateString) {
      const options = {year: 'numeric', month: 'short', day: 'numeric'};
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    resetNewReturnForm() {
      this.newReturn.linked_order = null;
      this.newReturn.issue_description = null;
      this.showNewReturnForm = false;
    },
    /**
     * Gets Tailwind classes for status badges.
     */
    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case 'open':
          return 'tw-bg-blue-100 tw-text-blue-800';
        case 'closed':
          return 'tw-bg-green-100 tw-text-green-800';
        default:
          return 'tw-bg-yellow-100 tw-text-yellow-800'; // Default for pending/other
      }
    },
    async submitNewReturn() {
      const payload = {
        linked_order: this.newReturn.linked_order,
        issue_description: this.newReturn.issue_description
      };

      let update = await this.coreService.createAftersales(payload);

      // Get toast interface
      const toast = useToast();
      if (update) {
        this.resetNewReturnForm();
        toast("Aftersales Request Submitted");
      } else {
        this.resetNewReturnForm();
        toast("An Error Occurred please try again later");

      }
    },
  }
};
</script>

<style scoped>
/* Scoped styles */
</style>
