<template>
  <!-- Extra Reference Input -->
  <div class="tw-mb-4">
    <label class="tw-block tw-font-medium tw-mb-2">
      Reference (Add Your Own Reference)
    </label>
    <input
        v-model="designerStore.selectedOptions.reference"
        type="text"
        class="tw-w-full tw-border tw-rounded tw-p-2"
    >
  </div>

  <!-- Price Display & Add to Cart -->
  <div class="tw-mb-4 tw-hidden md:tw-block">
          <span class="tw-text-xl tw-font-semibold">
            £{{ designerStore.pricing.price }} Inc Vat
          </span>
  </div>

  <span class="tw-hidden md:tw-block">
      <AddToCart
          @add-to-cart="$emit('submitCart')"
      />
  </span>


  <div
      v-if="designerStore.cartMessage"
      class="tw-mt-4 tw-text-sm tw-text-green-600"
  >
    {{ designerStore.cartMessage }}
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';
import AddToCart from './AddToCart.vue'
export default {
  name: "ProductFooter",
  components: {
    AddToCart
  },
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  }
}
</script>

<style scoped>
/* Tailwind CSS is assumed to be included globally. */
.button {
  border: none;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 15px;
}
</style>
