<template>
  <div
      class="tw-mx-auto tw-max-w-full tw-grid tw-grid-cols-1 md:grid-cols-2 tw-justify-center tw-px-6 md:tw-flex md:tw-space-x-6 xl:tw-px-0"
  >
    <div>
      <MethodSelect @update:paymentMethod="updatePaymentMethod" />

      <div
          v-if="coreStore.cart.cartItems.length > 0"
          class="tw-mt-6 tw-w-full tw-rounded-lg tw-border tw-bg-white tw-p-6 tw-shadow-md md:tw-mt-0"
          style="margin-top: 20px"
      >
        <div class="tw-mb-2 tw-flex tw-justify-between">
          <p class="tw-text-gray-700">Subtotal</p>
          <p class="tw-text-gray-700">
            £{{ formatCurrency(coreStore.cart.values.subTotal) }}
          </p>
        </div>
        <div class="tw-flex tw-justify-between">
          <p class="tw-text-gray-700">Shipping</p>
          <p class="tw-text-gray-700">
            £{{ formatCurrency(coreStore.cart.values.delivery) }}
          </p>
        </div>
        <div
            v-if="coreStore.cart.values.discountAmount > 0"
            class="tw-flex tw-justify-between"
        >
          <p class="tw-text-gray-700">Voucher Discount</p>
          <div class="tw-flex tw-items-center">
            <p class="tw-text-green-600">
              -£{{ formatCurrency(coreStore.cart.values.discountAmount) }}
            </p>
          </div>
        </div>
        <hr class="tw-my-4" />
        <div class="tw-flex tw-justify-between">
          <p class="tw-text-lg tw-font-bold">Total</p>
          <div class="">
            <p class="tw-mb-1 tw-text-lg tw-font-bold">
              £{{ formatCurrency(coreStore.cart.values.total) }}
            </p>
            <p class="tw-text-sm tw-text-gray-700">including VAT</p>
          </div>
        </div>
        <button
            class="tw-mt-6 tw-w-full tw-rounded-md tw-bg-blue-500 tw-py-1.5 tw-font-medium tw-text-blue-50 hover:tw-bg-blue-600"
            @click="coreService.changePage('cart')"
        >
          Back To Cart
        </button>
      </div>
    </div>

    <div
        class="tw-mt-6 tw-w-full md:tw-w-2/3 tw-rounded-lg tw-border tw-bg-white tw-p-6 tw-shadow-md md:tw-mt-0"
    >
      <h2 class="tw-text-xl tw-font-semibold" style="margin-bottom: 10px">
        Guest Checkout
      </h2>

      <form ref="checkoutForm" :action="getPostUrl()" method="post">
        <input type="hidden" name="payment-method" :value="paymentMethod"/>
        <input type="hidden" name="paymentMethod" :value="paymentMethod" />

        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
          <input
              v-model="formData.first_name"
              type="text"
              name="first_name"
              placeholder="First name"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
          <input
              v-model="formData.last_name"
              type="text"
              name="last_name"
              placeholder="Last name"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
          <input
              v-model="formData.phone"
              type="tel"
              name="phone"
              placeholder="Phone"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
          <input
              v-model="formData.email"
              type="email"
              name="email"
              placeholder="Email"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
        </div>

        <h3 class="tw-text-lg tw-font-medium tw-mt-4">Shipping Info</h3>
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
          <input
              v-model="formData.shipping_address_one"
              type="text"
              name="shipping_address_one"
              placeholder="Address Line 1"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
          <input
              v-model="formData.shipping_address_two"
              type="text"
              name="shipping_address_two"
              placeholder="Address Line 2"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
          />
          <input
              v-model="formData.shipping_address_city"
              type="text"
              name="shipping_city"
              placeholder="City"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
          <input
              v-model="formData.shipping_address_postcode"
              type="text"
              name="shipping_postcode"
              placeholder="Postal code"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
        </div>
        <h3 class="tw-text-lg tw-font-medium tw-mt-4">Billing Info</h3>
        <label class="tw-flex tw-items-center" style="margin-bottom: 20px">
          <input
              v-model="sameAsShipping"
              type="checkbox"
              class="tw-mr-2"
              name="billing_is_shipping"
              @change="updateBillingAddress"
          />
          Same As Shipping
        </label>
        <div v-if="!sameAsShipping" class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
          <input
              v-model="formData.billing_address_one"
              type="text"
              name="billing_address_one"
              placeholder="Address Line 1"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
          <input
              v-model="formData.billing_address_two"
              type="text"
              name="billing_address_two"
              placeholder="Address Line 2"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
          />
          <input
              v-model="formData.billing_address_city"
              type="text"
              name="billing_city"
              placeholder="City"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
          <input
              v-model="formData.billing_address_postcode"
              type="text"
              name="billing_postcode"
              placeholder="Postal code"
              class="tw-w-full tw-border tw-p-2 tw-rounded"
              required
          />
        </div>
        <label class="tw-flex tw-items-center tw-mt-2">
          <input
              v-model="formData.marketing_accepted"
              type="checkbox"
              name="marketing_accepted"
              class="tw-mr-2"
          />
          I agree to receive discounts, offers and promotions
        </label>
        <label class="tw-flex tw-items-center tw-mt-2">
          <input
              v-model="formData.acceptTerms"
              type="checkbox"
              name="acceptTerms"
              class="tw-mr-2"
              required
          />
          I accept the terms and conditions
        </label>
        <button
            class="tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-mt-4"
            type="submit"
        >
          SUBMIT
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useCoreStore } from "@/stores/CoreStore";
import { inject, ref } from "vue";
import MethodSelect from "@/components/backend/pages/checkout/components/MethodSelect.vue";

export default {
  name: "CheckoutPage",
  setup() {
    const coreStore = useCoreStore();
    const coreService = inject("coreService");
    const checkoutForm = ref("checkoutForm");

    return { coreStore, coreService, checkoutForm };
  },
  components: {
    MethodSelect,
  },
  data() {
    return {
      paymentMethod: 2,
      productsTotal: 5996.40,
      shippingCost: 20.00,
      sameAsShipping: true,
      formData: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        shipping_address_one: "",
        shipping_address_two: "",
        shipping_address_city: "",
        shipping_address_postcode: "",
        billing_address_one: "",
        billing_address_two: "",
        billing_address_city: "",
        billing_address_postcode: "",
        marketing_accepted: false,
        acceptTerms: false,
      },
    };
  },
  methods: {
    getPostUrl() {
      return process.env.VUE_APP_API_URL + '/checkout/submit?session=' + this.coreStore.cart.sessionId
    },
    formatCurrency(value) {
      if (typeof value === "number") {
        return value.toFixed(2);
      }
      return "0.00";
    },
    updatePaymentMethod(method) {
      this.paymentMethod = method;
    },
    updateBillingAddress() {
      if (this.sameAsShipping) {
        this.formData.billing_address_one = this.formData.shipping_address_one;
        this.formData.billing_address_two = this.formData.shipping_address_two;
        this.formData.billing_address_city = this.formData.shipping_address_city;
        this.formData.billing_address_postcode =
            this.formData.shipping_address_postcode;
      } else {
        this.formData.billing_address_one = "";
        this.formData.billing_address_two = "";
        this.formData.billing_address_city = "";
        this.formData.billing_address_postcode = "";
      }
    },
    submitForm(event) {
      event.preventDefault();

      if (this.checkoutForm.value) {
        this.checkoutForm.value.submit();
      }
    },
  },
};
</script>