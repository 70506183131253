<template>
  <div v-if="coreStore.auth.loading">
    <loading
        v-model:active="coreStore.auth.loading"
        :can-cancel="false"
        :is-full-page="true"
    />
  </div>
  <div v-else class="tw-min-h-screen tw-bg-gray-200 tw-p-4 lg:tw-p-8">
    <div class="tw-max-w-7xl tw-mx-auto tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">

      <aside class="tw-w-full lg:tw-w-64 tw-flex-shrink-0">
        <SidebarNav />
      </aside>

      <main class="tw-flex-grow tw-bg-white tw-rounded-lg tw-shadow-lg tw-overflow-hidden">
        <transition name="fade" mode="out-in">
          <component :is="activePage" />
        </transition>
      </main>

    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useAccountStore } from '@/stores/AccountStore';
import {useCoreStore} from "@/stores/CoreStore";

// Import Page Components
import SidebarNav from './components/SidebarNav.vue';
import AccountOverview from './components/AccountOverview.vue';
import AccountOrders from './components/AccountOrders.vue';
import AccountAddressBook from './components/AccountAddressBook.vue';
import AccountAftersales from './components/AccountAftersales.vue';
import Loading from "vue-loading-overlay";
import {inject} from "vue";
import {useAuthRedirector} from "@/compostables/AuthRedirect";

export default {
  name: 'App',
  components: {
    // Register all components needed in the template
    SidebarNav,
    AccountOverview,
    AccountOrders,
    AccountAddressBook,
    AccountAftersales,
    Loading
  },
  setup() {
    const coreStore = useCoreStore();
    const accountStore = useAccountStore();
    const coreService = inject('coreService');

    useAuthRedirector();

    return {coreStore, accountStore, coreService};
  },
  computed: {
    // Map the activePage state from the Pinia store
    ...mapState(useAccountStore, ['activePage']),
  },
  mounted() {
    this.coreService.loadAccount()
  }
};
</script>

<style>

/* Basic fade transition for page changes */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>
