<template>
  <!-- Main Product Section -->
  <div class="tw-grid sm:tw-grid-cols-12 tw-grid-cols-1 lg:tw-flex-row gap-6">
    <loading
        v-model:active="designerStore.variantLoading"
        :can-cancel="false"
        :is-full-page="true"
    />

    <!-- Options / Customisation section -->
    <div class="tw-col-span-5">
      <h1 class="mb-4" style="font-weight: bold; font-size: 1.8em">
        {{ designerStore.product.object.name }}
      </h1>

      <div v-html="designerStore.product.object.description" style="padding-bottom: 20px">

      </div>
      <template v-if="designerStore.product.linkedProducts">
        <LinkedProducts
            @product-changed="productChanged"
        />
      </template>

      <template
          v-if="designerStore.product.object.dimensional_uplifts && designerStore.product.object.dimensions_location == 1">
        <DimensionsInput
            @layer-update="layerUpdate"
        />
      </template>

      <VariantSelector
          @variant-changed="variantUpdate"
      />

      <template
          v-if="designerStore.product.object.dimensional_uplifts && designerStore.product.object.dimensions_location == 2">
        <DimensionsInput
            @layer-update="layerUpdate"
        />
      </template>

      <template
          v-for="layer in getLayersOrdered"
          :key="layer.id"
      >
        <LayerSelector
            v-if="!layer.empty"
            :layer="layer"
            @layer-update="layerUpdate"
        />
      </template>

      <template
          v-if="designerStore.product.object.dimensional_uplifts && designerStore.product.object.dimensions_location == 0">
        <DimensionsInput
            @layer-update="layerUpdate"
        />
      </template>

      <!-- Quantity Selector -->
      <QtySelector/>

      <!-- Product Footer Information -->
      <ProductFooter
          @submit-cart="submitCart"
      />

    </div>

    <!-- Image / View section -->
    <div
        class="tw-col-span-5 md:tw-sticky tw-relative"
        style="align-self: flex-end;bottom: 1rem;"
    >
      <div class="tw-relative" style="padding-top: 50px;">
        <p class="tw-text-center">
          Current View: {{ designerStore.renderView }}
        </p>
        <ImageLayering />
      </div>

      <div class="tw-mb-4">
        <div class="tw-text-center">
          <h6>Your Price</h6>
          <span class="tw-original-price tw-line-through"/>
          <span class="tw-text-lg">£<span id="pricing">{{ designerStore.pricing.price }}</span></span>
          <span class="tw-text-gray-500 tw-font-bold tw-px-1.5">Inc Vat</span>
          <br>
          <span class="tw-relative">
            <span
                v-if="designerStore.pricing.shippingFreeMin > designerStore.pricing.price"
                id="shipping-costs"
            >
              <small>+ Shipping £{{ designerStore.pricing.shippingPrice }}</small>
              <span
                  class="help-tip tw-absolute"
                  style="margin-left: 10px;"
              >
                <p>Total basket value is below £{{ designerStore.pricing.shippingFreeMin }}</p>
              </span>
            </span>
            <span
                v-else
                id="free-shipping"
            >
              <small>+ Free Shipping</small>
              <span
                  class="help-tip tw-absolute"
                  style="margin-left: 10px;"
              >
                <p>Total basket value is above £{{ designerStore.pricing.shippingFreeMin }}</p>
              </span>
            </span>
          </span>
          <br>
          <span class="tw-hidden md:tw-block">
            <AddToCart
              @addToCart="submitCart"
            />
          </span>
          <br>

          <div class="tw-relative tw-flex tw-py-2 tw-gap-2" style="justify-content: center; padding-bottom: 70px">
            <div></div>
            <ShareButton
                @generate-share="generateShare"
            />
            <CallbackRequest
              @callback="callbacKRequest"
            />
          </div>

        </div>


        <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-mb-4">
          <!-- Left spacer: 1/12 on mobile, 2/12 on medium+ -->
          <div class="tw-col-span-1 md:tw-col-span-2"/>
        </div>

        <div class="tw-mt-4 tw-text-center"/>
      </div>
    </div>

    <MobileToolbar
        @submit-cart="submitCart"
    />
    <div
        class="tw-col-span-2 md:tw-sticky tw-relative"
        style="align-self: flex-end;bottom: 1rem;"
    >
      <transition name="fade">
        <HelpContainer
            v-if="designerStore.helpArticle"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';
import {inject} from "vue";
import VariantSelector from './List/VariantSelector.vue'
import QtySelector from './List/QtySelector.vue'
import ProductFooter from './List/ProductFooter.vue'
import LayerSelector from './List/LayerSelector.vue'
import ImageLayering from './List/ImageLayering.vue'
import HelpContainer from './List/HelpContainer.vue'
import DimensionsInput from './List/DimensionsInput.vue'
import ShareButton from './List/ShareButton.vue'
import Loading from 'vue-loading-overlay';
import LinkedProducts from './List/LinkedProducts.vue'
import AddToCart from './List/AddToCart.vue'
import CallbackRequest from './List/CallbackRequest.vue'
import MobileToolbar from "@/components/backend/pages/designer/components/List/MobileToolbar.vue";
import 'vue-loading-overlay/dist/css/index.css';


export default {
  name: "ListLayout",
  components: {
    VariantSelector,
    QtySelector,
    ProductFooter,
    LayerSelector,
    ImageLayering,
    HelpContainer,
    DimensionsInput,
    ShareButton,
    Loading,
    LinkedProducts,
    AddToCart,
    CallbackRequest,
    MobileToolbar
  },
  setup() {
    const designerStore = useDesignerStore();
    return {designerStore};
  },
  data() {
    return {
      designerService: {}
    }
  },
  computed: {
    getLayersOrdered() {
      return Object.values(this.designerStore.product.layers).sort((a, b) => b.weight - a.weight)
    }
  },
  mounted() {
    let designerService = inject('designerService')
    this.designerService = designerService;

    designerService.getProduct();
  },
  methods: {
    variantUpdate() {
      this.designerService.loadVariant()
    },
    layerUpdate(layerId = null) {
      if (this.designerService.runUpdates) {
        this.designerService.runUpdates(layerId)
      }
    },
    generateShare() {
      if (this.designerService.generateShareLink) {
        this.designerService.generateShareLink()
      }
    },
    submitCart() {
      this.designerService.addToCart()
    },
    productChanged(productId) {
      this.designerStore.loading = true;
      this.designerService.getProduct(productId)
    },
    callbacKRequest (callbackData) {
      this.designerService.generateCallback(callbackData)
    }
  }
}
</script>


<style scoped>

.help-tip {
  position: absolute;
  text-align: center;
  background-color: #29b6f6;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  z-index: 1000;

  font-size: 12px;
  line-height: 26px;
  cursor: default;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.help-tip:hover {
  cursor: pointer;
  background-color: #ccc;
}

.help-tip:before {
  content: '?';
  font-weight: 700;
  color: #fff;
}

.help-tip p {
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  text-align: left;
  background-color: #039be5;
  padding: 20px;
  width: 300px;
  position: absolute;
  border-radius: 4px;
  right: -4px;
  color: #fff;
  font-size: 13px;
  line-height: normal;
  transform: scale(0.7);
  transform-origin: 100% 0%;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.help-tip:hover p {
  cursor: default;
  visibility: visible;
  opacity: 1;
  transform: scale(1.0);
}

.help-tip p:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #039be5;
  right: 10px;
  top: -12px;
}

.help-tip p:after {
  width: 100%;
  height: 40px;
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 10000;
}

.help-tip a {
  color: #fff;
  font-weight: 700;
}

.help-tip a:hover, .help-tip a:focus {
  color: #fff;
  text-decoration: underline;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

/* Tailwind CSS is assumed to be included globally. */
.button {
  border: none;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 100px;
}
</style>