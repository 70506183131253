<template>
  <div class="tw-flex tw-items-center tw-space-x-4 tw-mb-4">
    <button
        class="text-white p-2 tw-rounded"
        style="padding: 10px; color: #fff"
        :style="{
          backgroundColor: designerStore.branding.branding_colour
        }"
        @click="designerStore.decrementQty"
    >
      -
    </button>
    <input
        v-model.number="designerStore.selectedOptions.qty"
        type="number"
        min="1"
        class="tw-w-16 tw-border tw-rounded p-1 tw-text-center"
    >
    <button
        class="text-white p-2 tw-rounded"
        style="padding: 10px; color: #fff"
        :style="{
          backgroundColor: designerStore.branding.branding_colour
        }"
        @click="designerStore.incrementQty"
    >
      +
    </button>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';
import {inject} from "vue";

export default {
  name: "QtySelector",
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  }
}
</script>

<style scoped>
/* Tailwind CSS is assumed to be included globally. */
</style>
