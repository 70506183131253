<template>
  <button
      class="add-to-cart"
      :class="{ added, loading }"
      @click="handleAddToCart"
      :style="{
        backgroundColor: designerStore.branding.branding_colour,
        '--background': designerStore.branding.branding_colour
      }"
  >
    <div class="default" v-if="!loading">Add to cart</div>
    <div class="success">Added</div>
    <div class="cart">
      <div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="dots"></div>
    <div class="loading-dots" v-if="loading">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
</template>

<script>
import { useDesignerStore } from "@/stores/DesignerStore";

export default {
  setup() {
    const designerStore = useDesignerStore();
    return { designerStore };
  },
  data() {
    return {
      added: false,
      loading: false
    };
  },
  methods: {
    handleAddToCart() {
      const designerStore = useDesignerStore();

      if (this.added || this.loading) return;
      this.$emit('addToCart');

      this.loading = true; // Show loading dots

      let checkStatus = setInterval(() => {
        if (designerStore.addedToCart) {
          clearInterval(checkStatus);
          this.loading = false;
          this.added = true;

          setTimeout(() => {
            designerStore.addedToCart = false;
            this.added = false;
          }, 5000);
        }
      }, 500);
    }
  }
};
</script>


<style scoped>
.add-to-cart {
  --color: #fff;
  --icon: var(--color);
  --cart: #fff;
  --dots: #fff;
  --background: #275EFE;
  --shadow: rgba(0, 9, 61, 0.16);
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 22px;
  padding: 12px 32px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background: var(--background);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 10px;
}
.add-to-cart .default {
  padding-left: 16px;
  position: relative;
  opacity: var(--o, 1);
  transform: scale(var(--s, 1));
  transition: transform 0.3s, opacity 0.3s;
  transition-delay: var(--d, 0.3s);
}
.add-to-cart .default:before,
.add-to-cart .default:after {
  content: "";
  width: 2px;
  height: 12px;
  left: 0;
  top: 4px;
  border-radius: 1px;
  background: var(--icon);
  position: absolute;
  transform: rotate(var(--r, 0deg));
  transition: transform 0.25s;
}
.add-to-cart .default:after {
  --r: 90deg;
}
.add-to-cart .success {
  opacity: var(--o, 0);
  transform: translate(-50%, var(--y, 12px));
  position: absolute;
  top: 12px;
  left: 50%;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: var(--d, 0s);
}
.add-to-cart .dots {
  width: 4px;
  height: 4px;
  top: 20px;
  left: 50%;
  margin-left: -7px;
  border-radius: 2px;
  position: absolute;
  background: var(--dots);
  box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
  opacity: var(--o, 0);
  transform: scale(var(--s, 0.4));
  transition: opacity 0.3s, transform 0.3s;
}
.add-to-cart .cart {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: inherit;
  overflow: hidden;
}
.add-to-cart .cart:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 16px;
  background: var(--background);
  top: 17px;
  right: 100%;
  margin-right: -20px;
  transform: translateX(-18px) rotate(-16deg);
}
.add-to-cart .cart > div {
  top: 13px;
  right: 100%;
  transform: translateX(-18px) rotate(-16deg);
  position: absolute;
  z-index: 2;
  transform-origin: 1px 21px;
}
.add-to-cart .cart > div:before,
.add-to-cart .cart > div:after {
  content: "";
  position: absolute;
  top: var(--t, 4px);
  left: var(--l, 0);
  height: 2px;
  width: var(--w, 18px);
  background: var(--cart);
  border-radius: 1px;
}
.add-to-cart .cart > div:after {
  --w: 16px;
  --t: 14px;
  --l: 1px;
}
.add-to-cart .cart > div > div {
  width: 2px;
  height: var(--h, 15px);
  border-radius: 1px;
  transform: rotate(var(--r, -8deg));
  background: var(--cart);
  position: relative;
}
.add-to-cart .cart > div > div:before,
.add-to-cart .cart > div > div:after {
  content: "";
  position: absolute;
  background: inherit;
}
.add-to-cart .cart > div > div:after {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  bottom: var(--b, -6px);
  left: var(--l, 0);
}
.add-to-cart .cart > div > div:first-child:before {
  border-radius: inherit;
  top: 0;
  right: 0;
  height: 2px;
  width: 6px;
  transform-origin: 5px 1px;
  transform: rotate(16deg);
}
.add-to-cart .cart > div > div:last-child {
  --h: 12px;
  --r: 8deg;
  position: absolute;
  left: 16px;
  bottom: -1px;
}
.add-to-cart .cart > div > div:last-child:after {
  --l: -2px;
  --b: -5px;
}
.add-to-cart.added .default {
  --o: 0;
  --s: .8;
  --d: 0s;
}
.add-to-cart.added .default:before {
  --r: -180deg;
}
.add-to-cart.added .default:after {
  --r: -90deg;
}
.add-to-cart.added .dots {
  --o: 1;
  --s: 1;
  --d: .3s;
  animation: dots 2s linear forwards;
}
.add-to-cart.added .success {
  --o: 1;
  --y: 0;
  --d: 1.8s;
}
.add-to-cart.added .cart:before,
.add-to-cart.added .cart > div {
  animation: cart 2s forwards;
}
.add-to-cart:not(.added):hover {
  --s: 1;
  --s-y: 8px;
  --s-b: 20px;
}
.add-to-cart:not(.added):active {
  --s: .94;
  --s-y: 2px;
  --s-b: 6px;
}

@keyframes cart {
  41%, 49%, 57%, 60% { transform: translateX(72px) rotate(0deg); }
  100% { transform: translateX(180px) rotate(-16deg); }
}

@keyframes dots {
  25% { transform: translateY(-20px) rotate(-32deg); }
  32%, 50% { transform: translateY(0) rotate(0deg); opacity: 1; }
  51%, 100% { opacity: 0; }
}


/* Loading dots animation */
.loading-dots {
  display: flex;
  gap: 4px;
}

.loading-dots span {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  animation: bounce 1.4s infinite;
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
