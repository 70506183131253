<template>
  <div v-if="designerStore.loading" class="tw">
    <div class="tw-h-screen tw-flex tw-items-center tw-justify-center">
      <VueSpinnerOrbit size="8em" />
    </div>
  </div>
  <div
      v-else
      class="tw-container tw-mx-auto tw-px-4 tw-py-6"
  >
    <template v-if="designerStore.designerMode == 0">
      <ListLayout />
    </template>
    <template v-else-if="designerStore.designerMode == 1">
      <StepperLayout />
    </template>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';
import {VueSpinnerOrbit} from "vue3-spinners";
import {inject} from "vue";
import ListLayout from './components/ListLayout.vue'
import StepperLayout from './components/StepperLayout.vue'

export default {
  name: "DesignerPage",
  components: {
    ListLayout,
    StepperLayout,
    VueSpinnerOrbit,
  },
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  },
  data() {
    return {
      designerService: {},
      mode: "1"
    }
  },
  mounted() {
    let designerService = inject('designerService')
    this.designerService = designerService;

    designerService.getProduct();
  }
}
</script>

<style>

.cc-active:before{
  content: "✔";
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px;
  display: block;
  padding: 0;
  width: 25px;
  height: 25px;
  font-size: 13px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #3399bb;
  border-radius: 6px 0 0 ;
  color: #fff;
}

</style>
