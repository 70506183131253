<template>
  <div
      v-for="item in coreStore.cart.cartItems"
      :key="item.id"
      class="tw-justify-between tw-mb-6 tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-md sm:tw-flex sm:tw-justify-start"
  >
    <img
        :src="item.image"
        alt="product-image"
        style="max-height: 400px; width: auto !important; height: auto"
        class="tw-max-w-full tw-rounded-lg sm:tw-w-40"
    />
    <div class="sm:tw-ml-4 sm:tw-flex sm:tw-w-full sm:tw-justify-between">
      <div class="tw-mt-5 sm:tw-mt-0">
        <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900" style="margin-right: 50px">
          {{ item.name }}
        </h2>
        <div class="tw-mt-1 tw-text-md tw-text-gray-700 description-container">
          <div v-html="item.description" :class="{ expanded: item.expanded }"></div>
          <button
              v-if="item.description && item.description.length > 100"
              @click="toggleExpand(item)"
              class="tw-text-blue-500 tw-cursor-pointer"
          >
            {{ item.expanded ? 'Read Less' : 'Read More' }}
          </button>
        </div>
      </div>
      <div class="tw-mt-6 tw-flex tw-justify-between sm:tw-space-y-10 sm:tw-mt-0 sm:tw-block sm:tw-space-x-10">
        <div class="tw-flex tw-items-center tw-border-gray-100">
          <span
              class="tw-cursor-pointer tw-rounded-l tw-bg-gray-100 tw-py-2 tw-px-3.5 tw-duration-100 hover:tw-bg-blue-500 hover:tw-text-blue-50"
              @click="decrementQuantity(item)"
          >
            -
          </span>
          <input
              class="tw-h-10 tw-w-10 tw-border tw-bg-white tw-text-center tw-text-xs tw-outline-none"
              type="number"
              :value="item.quantity"
              min="1"
              @change="updateQuantityInput(item, $event)"
          />
          <span
              class="tw-cursor-pointer tw-rounded-r tw-bg-gray-100 tw-py-2 tw-px-3 tw-duration-100 hover:tw-bg-blue-500 hover:tw-text-blue-50"
              @click="incrementQuantity(item)"
          >
            +
          </span>
        </div>
        <div class="tw-flex tw-items-center tw-space-x-4">
          <p class="tw-text-xl">£{{ formatCurrency(item.price) }}</p>
          <svg
              @click="removeItem(item.id, item.name)"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="tw-h-5 tw-w-5 tw-cursor-pointer tw-duration-150 hover:tw-text-red-500"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCoreStore } from "@/stores/CoreStore";
import Swal from "sweetalert2";

export default {
  name: "CartItem",
  inject: ['coreService'],
  data() {
    return {};
  },
  computed: {
    coreStore() {
      return useCoreStore();
    },
  },
  methods: {
    toggleExpand(item) {
      if (!Object.prototype.hasOwnProperty.call(item, "expanded")) {
        this.$set(item, 'expanded', true); // Use $set to make it reactive
      } else {
        item.expanded = !item.expanded;
      }
    },
    incrementQuantity(item) {
      this.coreStore.updateCartItemQuantity({
        itemId: item.id,
        quantity: item.quantity + 1,
      });
    },
    decrementQuantity(item) {
      if (item.quantity > 1) {
        this.coreStore.updateCartItemQuantity({
          itemId: item.id,
          quantity: item.quantity - 1,
        });
      }
    },
    updateQuantityInput(item, event) {
      const newQuantity = parseInt(event.target.value, 10);
      if (newQuantity > 0) {
        this.coreStore.updateCartItemQuantity({
          itemId: item.id,
          quantity: newQuantity,
        });
      } else {
        event.target.value = item.quantity;
      }
    },
    removeItem(cartItem, itemName) {
      Swal.fire({
        title: 'Are you sure you want to delete ' + itemName + '?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.coreService.removeCartItem(cartItem)
        }
      });
    },
    formatCurrency(value) {
      value = parseFloat(value);
      if (typeof value === 'number') {
        return value.toFixed(2);
      }
      return '0.00';
    },
  },
};
</script>

<style scoped>
.description-container div {
  max-height: 400px;
  overflow: hidden;
  position: relative;
}

.description-container div.expanded {
  max-height: none;
  overflow: visible;
}

.description-container div:not(.expanded)::after {
  content: "...";
  bottom: 0;
  right: 0;
  background: white;
  padding-left: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>