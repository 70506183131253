<template>
  <div v-if="coreStore.cart.loading">
    <loading
        v-model:active="coreStore.cart.loading"
        :can-cancel="false"
        :is-full-page="true"
    />
  </div>
  <div
      v-else
      class="tw-h-screen tw-pt-20"
  >
    <div class="tw-mx-auto tw-max-w-full tw-justify-center tw-px-6 md:tw-flex md:tw-space-x-6 xl:tw-px-0" style="margin: 20px !important;">
      <div class="tw-rounded-lg md:tw-w-2/3">
        <div v-if="coreStore.cart.cartItems.length > 0">
          <CartItem/>
        </div>
        <div v-else>
          <h4 style="width: 100%; text-align: center; font-size: 40px; font-weight: bold; color: grey;">
            Cart Is Empty
          </h4>
        </div>
      </div>
      <div
          v-if="coreStore.cart.cartItems.length > 0"
          class="tw-mt-6 tw-h-full tw-rounded-lg tw-border tw-bg-white tw-p-6 tw-shadow-md md:tw-mt-0 md:tw-w-1/3"
      >
        <div class="tw-mb-2 tw-flex tw-justify-between">
          <p class="tw-text-gray-700">
            Subtotal
          </p>
          <p class="tw-text-gray-700">
            £{{ formatCurrency(coreStore.cart.values.subTotal) }}
          </p>
        </div>
        <div class="tw-flex tw-justify-between">
          <p class="tw-text-gray-700">
            Shipping
          </p>
          <p class="tw-text-gray-700">
            £{{ formatCurrency(coreStore.cart.values.delivery) }}
          </p>
        </div>
        <div
            v-if="coreStore.cart.values.discountAmount > 0"
            class="tw-flex tw-justify-between"
        >
          <p class="tw-text-gray-700">
            Voucher Discount
          </p>
          <div class="tw-flex tw-items-center">
            <p class="tw-text-green-600">
              -£{{ formatCurrency(coreStore.cart.values.discountAmount) }}
            </p>
            <button
                class="tw-ml-2 tw-text-red-500"
                @click="removeVoucher"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="tw-h-5 tw-w-5 tw-cursor-pointer tw-duration-150 hover:tw-text-red-500"
                  style="margin-top: 2px;"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <hr class="tw-my-4">
        <div class="tw-flex tw-justify-between">
          <p class="tw-text-lg tw-font-bold">
            Total
          </p>
          <div class="">
            <p class="tw-mb-1 tw-text-lg tw-font-bold">
              £{{ formatCurrency(coreStore.cart.values.total) }}
            </p>
            <p class="tw-text-sm tw-text-gray-700">
              including VAT
            </p>
          </div>
        </div>
        <button
            class="tw-mt-6 tw-w-full tw-rounded-md tw-bg-blue-500 tw-py-1.5 tw-font-medium tw-text-blue-50 hover:tw-bg-blue-600"
            @click="coreService.changePage('checkout')"
        >
          Check out
        </button>
        <div class="tw-mt-6">
          <button
              class="tw-mt-2 tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50"
              @click="openVoucherModal"
          >
            Add Voucher
          </button>
          <hr style="margin-top: 10px; margin-bottom: 10px">
          <button
              class="tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50"
              @click="openSaveCartModal"
          >
            Save Your Cart
          </button>
          <button
              class="tw-mt-2 tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50"
              @click="openCallbackModal"
          >
            Request Callback
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useCoreStore} from "@/stores/CoreStore";
import CartItem from "./components/CartItem.vue";
import Loading from "vue-loading-overlay";
import Swal from 'sweetalert2';
import {inject} from 'vue';

export default {
  name: "CartPage",
  components: {
    CartItem,
    Loading,
  },
  setup() {
    const coreStore = useCoreStore();
    const coreService = inject('coreService')
    return {coreStore, coreService};
  },
  methods: {
    formatCurrency(value) {
      if (typeof value === 'number') {
        return value.toFixed(2);
      }
      return '0.00';
    },
    removeVoucher() {
      console.log("Do Something here sometime")
      Swal.fire({
        title: 'Do you wish to remove this voucher from your cart?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("remove voucher")

          let response = await this.coreService.removeVoucher()

        }
      });
    },
    openSaveCartModal() {
      Swal.fire({
        title: 'Save Your Cart',
        input: 'email',
        inputPlaceholder: 'Enter your email address',
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let email = result.value;

          let save = await this.coreService.saveCart(email)

          if (save == true) {
            Swal.fire(
                'Completed!',
                'The basket has been saved and emailed to you',
                'success'
            )
          } else {
            Swal.fire(
                'Error!',
                save,
                'error'
            )
          }
        }
      });
    },
    openCallbackModal() {
      Swal.fire({
        title: 'Request Callback',
        html:
            '<input id="swal-input1" class="swal2-input" placeholder="Name">' +
            '<input id="swal-input2" class="swal2-input" placeholder="Number">',
        showCancelButton: true,
        confirmButtonText: 'Request',
        preConfirm: () => {
          const name = Swal.getPopup().querySelector('#swal-input1').value;
          const number = Swal.getPopup().querySelector('#swal-input2').value;
          console.log("Requesting callback:", {name, number}); // Mock server call
          return new Promise((resolve) => {
            resolve([
              name,
              number
            ]);
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          let phone = result.value[1];
          let name = result.value[0];
          let postData = {
            'phone': phone,
            'name': name,
          }

          let savePost = await this.coreService.requestCallback(postData)

          if (savePost == true) {
            Swal.fire(
                'Completed!',
                'A callback has been requested we will be in contact shortly',
                'success'
            )
          } else {
            Swal.fire(
                'Error!',
                savePost,
                'error'
            )
          }
        }
      });
    },
    openVoucherModal() {
      Swal.fire({
        title: 'Add Voucher',
        input: 'text',
        inputPlaceholder: 'Enter voucher code',
        showCancelButton: true,
        confirmButtonText: 'Apply',
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let voucher = result.value

          let save = await this.coreService.addVoucher(voucher)

          if (save == true) {
            Swal.fire(
                'Completed!',
                'The voucher was added to your cart',
                'success'
            )
          } else {
            Swal.fire(
                'Error!',
                save,
                'error'
            )
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>