<template>
  <div
      id="variant-selector"
      class="tw-pb-8"
      tabindex="0"
  >
    <div class="tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden">
      <!-- Header -->
      <h5
          v-if="designerStore.designerMode == 0"
          class="tw-text-white tw-bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] tw-p-4"
          :style="{
            backgroundColor: designerStore.branding.branding_colour
          }"
      >
        {{ layer.name }}
      </h5>
      <!-- Card body -->
      <div class="tw-p-4">
        <div class="tw-flex tw-flex-wrap">
          <!-- Option -->
          <span
              v-for="option in visibleOptions"
              :key="option.id"
              :style="{
                    width: getWidth(),
                    top: 0,
                    right: 0,
                    minHeight: getHeight(),
                    cursor: 'pointer'
                  }"
              class="tw-px-2 tw-m-2"
              @click="changeOption(layer, option)"
              :class="[
              'tw-p-2 tw-border tw-rounded-md tw-relative',
              designerStore.selectedOptions[layer.id] === option.id
                ? 'cc-active'
                : 'tw-border-gray-300'
            ]"
          >
            <div
                :style="{
                    width: getWidth(),
                    minHeight: getHeight()
                  }"
            >
              <img
                  :src="option.thumbnail"
                  alt=""
                  class="tw-object-cover tw-rounded tw-absolute"
                  :style="{
                    width: getWidth(),
                    top: 0,
                    right: 0,
                    height: getHeight()
                  }"
              >
            </div>
              <p
                  class="tw-text-center tw-text-sm"
                  :style="{
                    top: getHeight(),
                    left: 0
                  }">
                {{ option.name }}
                <span v-if="option.price">
                  [+£{{ option.price }}]
                </span>
              </p>
          </span>
        </div>

        <div
            v-for="(input, index) in visibleInputs"
            :key="input.input_name"
        >
          <label :for="generateId(index)" class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">{{ input.input_name }}</label>
          <input
              :id="generateId(index)"
              v-model="designerStore.selectedOptions[generateId(index)]"
              type="text"
              class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-p-2.5"
              placeholder=""
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';

export default {
  name: "LayerSelector",
  props: ['layer'],
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  },
  computed: {
    visibleOptions() {
      // Convert the options object to an array, then filter out any with hidden === true
      return Object.values(this.layer.options).filter(option => option.hidden === false);
    },
    selectedOption() {
      return this.layer.options[this.designerStore.selectedOptions[this.layer.id]]
    },
    visibleInputs() {
      if (this.selectedOption) {
        return Object.values(this.selectedOption.inputs).filter(option => option.is_dimension === false);
      }

      return [];
    }
  },
  methods: {
    getWidth() {
      if (this.layer.custom_option_width) return this.layer.custom_option_width + 'px';
      return '100px';
    },
    generateId(index) {
      console.log(index)

      return 'field-layer-' + this.layer.id + '-field-' + index
    },
    getHeight() {
      if (this.layer.custom_option_height) return this.layer.custom_option_height + 'px';
      return '100px';
    },
    changeOption(layer, option) {
      this.designerStore.changeOption(layer, option)
      this.$emit('layer-update', layer.id)
    }
  }
}
</script>

<style scoped>
/* Tailwind CSS is assumed to be included globally. */
</style>
