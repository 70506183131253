<template>
  <div class="tw-p-6 tw-bg-gray-100 tw-rounded-lg tw-shadow-inner">
    <h1 class="tw-text-2xl tw-font-semibold tw-mb-1 tw-text-gray-800">
      My Orders
    </h1>
    <nav class="tw-text-sm tw-text-gray-500 tw-mb-6">
      <span>Home</span> &gt; <span>My Account</span> &gt; <span class="tw-font-medium tw-text-gray-700">My Orders</span>
    </nav>

    <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
      <h2 class="tw-text-xl tw-font-semibold tw-mb-4 tw-text-gray-700">
        Order History
      </h2>
      <div class="tw-overflow-x-auto">
        <table class="tw-min-w-full tw-divide-y tw-divide-gray-200">
          <thead class="tw-bg-gray-50">
          <tr>
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Order ID
            </th>
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Date
            </th>
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Price
            </th>
<!--            <th-->
<!--                scope="col"-->
<!--                class="tw-px-4 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"-->
<!--            >-->
<!--              Status-->
<!--            </th>-->
            <th
                scope="col"
                class="tw-px-4 tw-py-3 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
            >
              Actions
            </th>
          </tr>
          </thead>
          <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
          <tr v-if="getOrders.length === 0">
            <td
                colspan="5"
                class="tw-px-4 tw-py-4 tw-text-center tw-text-sm tw-text-gray-500"
            >
              You have not placed any orders yet.
            </td>
          </tr>
          <tr
              v-for="order in getOrders"
              :key="order.id"
              class="hover:tw-bg-gray-50"
          >
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
              #{{ order.id }}
            </td>
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
              {{ formatDate(order.created_at) }}
            </td>
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
              £{{ order.order_value.toLocaleString() }}
            </td>
<!--            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap">-->
<!--                 <span-->
<!--                     :class="getStatusClass('unknown')"-->
<!--                     class="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full"-->
<!--                 >-->
<!--                   Status Unknown-->
<!--                 </span>-->
<!--            </td>-->
            <td class="tw-px-4 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">
              <button
                  class="tw-text-blue-600 hover:tw-text-blue-900 tw-mr-2"
                  @click="viewOrderDetails(order.id)"
              >
                Details
              </button>
              <!--              <button v-if="canReturn(order.status)" @click="returnOrder(order.id)" class="tw-text-orange-600 hover:tw-text-orange-900 tw-ml-2">Open Aftersales</button>-->
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'pinia';
import {useAccountStore} from '@/stores/AccountStore';

export default {
  name: 'AccountOrders',
  computed: {
    // Map state from the Pinia store using the getter
    ...mapState(useAccountStore, ['getOrders']),

  },
  methods: {
    // Map actions
    ...mapActions(useAccountStore, ['setActivePage']),
    /**
     * Formats a date string.
     */
    formatDate(dateString) {
      const options = {year: 'numeric', month: 'short', day: 'numeric'};
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    /**
     * Gets Tailwind classes for status badges.
     */
    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case 'in transit':
          return 'tw-bg-yellow-100 tw-text-yellow-800';
        case 'pre-order':
          return 'tw-bg-blue-100 tw-text-blue-800';
        case 'delivered':
          return 'tw-bg-green-100 tw-text-green-800';
        case 'cancelled':
          return 'tw-bg-red-100 tw-text-red-800';
        default:
          return 'tw-bg-gray-100 tw-text-gray-800';
      }
    },

    /**
     * Placeholder for viewing detailed order information.
     * @param {string} orderId - The ID of the order.
     */
    viewOrderDetails(orderId) {
      console.log('View details for order:', orderId);
      // Navigate to a specific order detail page or show a modal
      alert(`Showing details for order #${orderId} (mock)`);
    },

    /**
     * Placeholder for initiating a return for an order.
     * @param {string} orderId - The ID of the order to return.
     */
    returnOrder(orderId) {
      console.log('Return order:', orderId);
      alert(`Initiating return for order #${orderId} (mock)`);
      // Navigate to the aftersales/returns page or start return flow
      this.setActivePage('AccountAftersales');
    },

    /**
     * Determines if an order can be reordered (e.g., if delivered).
     * @param {string} status - The order status.
     * @returns {boolean}
     */
    canReorder(status) {
      return status.toLowerCase() === 'delivered';
    },

    /**
     * Determines if an order can be returned (e.g., if delivered and within return window).
     * @param {string} status - The order status.
     * @returns {boolean}
     */
    canReturn(status) {
      // Add logic for return window if needed
      return status.toLowerCase() === 'delivered';
    }
  }
};
</script>

<style scoped>
/* Scoped styles */
</style>
