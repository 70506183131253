<template>
  <div id="help-container" v-html="designerStore.helpArticle">
  </div>
</template>

<script>
import {useDesignerStore} from "@/stores/DesignerStore";

export default {
  name: "HelpContainer",
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  },
}
</script>

<style scoped>
#help-container img {
  max-width: 100%;
  height: auto;
}

#help-container {
  overflow-y: scroll;
  padding: 20px;
  height: 90vh;
  min-width: 250px;
  background: rgb(220, 218, 218);
  position: sticky;
  align-self: flex-end;
  bottom: 1rem;
  border-radius: 10px;
  margin: 20px;
}


</style>