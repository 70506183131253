<template>
  <div
      id="variant-selector"
      class="tw-pb-8"
  >
    <div class="tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden">
      <!-- Header -->
      <h5
          class="tw-text-white tw-bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] tw-p-4"
          :style="{
            backgroundColor: designerStore.branding.branding_colour
          }"
      >
        Select A {{ getToken }}
      </h5>
      <!-- Card body -->
      <div class="tw-p-4">
        <div
            class="tw-flex tw-flex-wrap"
        >
          <!-- Option -->
          <span
              v-for="variant in visibleOptions"
              :key="variant.id"
              class="tw-px-2 tw-m-2"
              :class="[
              'tw-p-2 tw-border tw-rounded-md tw-relative',
              designerStore.selectedOptions['variantId'] === variant.id
                ? 'cc-active'
                : 'tw-border-gray-300'
            ]"
              @click="variantUpdate(variant)"
          >
            <div
                :style="{
                    height: getHeight(variant),
                    width: getWidth(variant)
                  }"
            >
              <img
                  :src="variant.thumbnail_url"
                  alt=""
                  class="tw-object-contain tw-rounded tw-absolute"
                  style="top: 0; left: 8px"
                  :style="{
                    height: getHeight(variant),
                    width: getWidth(variant)
                  }"
              >
            </div>
            <p class="tw-text-center tw-text-sm"
               :style="{
                    width: getWidth(variant)
                  }"
            >
              {{ variant.name }}
              <span v-if="variant.uplift && !designerStore.product.object.hide_variant_costs">
                [+£{{ variant.uplift }}]
              </span>
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';
import {inject} from "vue";

export default {
  name: "VariantSelector",
  setup() {
    const designerStore = useDesignerStore();
    const designerService = inject('designerService')

    return {designerStore, designerService};
  },
  computed: {
    visibleOptions() {
      // Convert the options object to an array, then filter out any with hidden === true
      return Object.values(this.designerStore.product.variants).filter(option => option.hidden == 0);
    },
    getToken () {

      if (this.designerStore.product.object.variant_tokenisation) {
        return this.designerStore.product.object.variant_tokenisation
      }

      return 'Variant';
    }
  },
  methods: {
    variantUpdate(variant) {
      this.designerStore.setVariant(variant)
      this.$emit('variantChanged')
    },
    getHeight (item) {
      if (item.custom_option_height) {
        return item.custom_option_height + 'px'
      }
      return 200 + 'px !important';
    },
    getWidth (item) {
      if (item.custom_option_width) {
        return item.custom_option_width + 'px'
      }
      return 200 + 'px !important';
    }
  }
}
</script>

<style scoped>
/* Tailwind CSS is assumed to be included globally. */
</style>