<template>
  <div class="tw-card tw-mb-4">
    <div class="tw-card-header tw-py-3">Select Payment Method</div>

    <div class="tw-card-body">
      <div class="tw-mx-auto tw-p-4 tw-payment-container">
        <div class="tw-grid tw-grid-cols-1 tw-gap-4">
          <div
              v-if="!disableSuper"
              class="tw-card"
              :class="{ 'tw-selected-payment': paymentMethod === '2' }"
              @click="updatePaymentMethod('2')"
          >
            <input
                type="radio"
                id="super_payment"
                name="payment_option"
                style="display: none"
                value="2"
                v-model="paymentMethod"
            />
            <label for="super_payment" class="tw-flex tw-items-center">
              <div class="tw-w-1/2 tw-relative tw-h-20">
                <div class="tw-centre-div">
                  <img
                      style="max-width: 100%; max-height: 100%;"
                      src="https://theme.zdassets.com/theme_assets/14525808/5872d2ddd6198de910c00224c42696056259342a.png"
                  />
                  <img
                      style="max-width: 50%; max-height: 100%;"
                      src="https://pngimg.com/d/visa_PNG33.png"
                  />
                </div>
              </div>
              <div class="tw-w-1/2 tw-text-center tw-pr-2">
                <h6>Pay By Super</h6>
                <p class="tw-text-sm">
                  Pay By Super Using Open Banking And Debit/Credit Cards For Cash
                  Rewards!
                </p>
              </div>
            </label>
          </div>

          <div
              class="tw-card"
              :class="{ 'tw-selected-payment': paymentMethod === '1' }"
              @click="updatePaymentMethod('1')"
          >
            <input
                type="radio"
                id="card_payment"
                name="payment_option"
                style="display: none"
                value="1"
                v-model="paymentMethod"
            />
            <label for="card_payment" class="tw-flex tw-items-center">
              <div class="tw-w-1/2 tw-relative tw-h-20">
                <div class="tw-centre-div">
                  <img
                      style="max-width: 100%; max-height: 100%;"
                      src="https://pngimg.com/d/visa_PNG33.png"
                  />
                </div>
              </div>
              <div class="tw-w-1/2 tw-text-center">
                <h6>Pay By Debit/Credit Card</h6>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    disableSuper: {
      type: Boolean,
      default: false,
    },
    paymentDefault: {
      type: Number,
      default: 2,
    },
  },
  setup(props, { emit }) {
    const paymentMethod = ref(props.paymentDefault === 2 ? '2' : '1');

    const updatePaymentMethod = (method) => {
      paymentMethod.value = method;
      emit('update:paymentMethod', method);
    };

    return {
      paymentMethod,
      updatePaymentMethod,
    };
  },
};
</script>

<style scoped>
.tw-centre-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tw-selected-payment {
  box-shadow: 0px 0 4px 6px #d2fd72 !important;
}

.tw-card {
  @apply tw-rounded-md tw-border tw-bg-white tw-shadow-md;
}

.tw-card-header {
  @apply tw-py-3 tw-px-4 tw-font-semibold tw-text-lg;
}

.tw-card-body {
  @apply tw-p-4;
}

.tw-payment-container {
  @apply tw-mx-auto;
}
</style>