<template>
  <div class="tw-w-64 tw-bg-gray-800 tw-text-white tw-p-4 tw-flex tw-flex-col tw-h-full tw-rounded-lg tw-shadow-md">
    <div class="tw-mb-6 tw-flex tw-items-center tw-border-b tw-border-gray-700 tw-pb-4">
      <img :src="'https://placehold.co/40x40/E0E7FF/4F46E5?text=' + coreStore.auth.user.first_name[0] + coreStore.auth.user.last_name[0] " alt="User Avatar" class="tw-w-10 tw-h-10 tw-rounded-full tw-mr-3">
      <div>
        <p class="tw-font-semibold tw-text-sm">{{ coreStore.auth.user.first_name }} {{ coreStore.auth.user.last_name }}</p>
        <p class="tw-text-xs tw-text-gray-400">{{ coreStore.auth.user.email }}</p>
      </div>
    </div>

    <nav class="tw-flex-grow">
      <ul>
        <li class="tw-mb-2">
          <button
              @click="navigateTo('AccountOverview')"
              :class="[
              'tw-w-full tw-text-left tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium tw-flex tw-items-center',
              isActive('AccountOverview')
                ? 'tw-bg-gray-900 tw-text-white'
                : 'tw-text-gray-300 hover:tw-bg-gray-700 hover:tw-text-white'
            ]"
          >
            <span class="tw-mr-2">🏠</span> Overview
          </button>
        </li>
        <li class="tw-mb-2">
          <button
              @click="navigateTo('AccountOrders')"
              :class="[
              'tw-w-full tw-text-left tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium tw-flex tw-items-center',
              isActive('AccountOrders')
                ? 'tw-bg-gray-900 tw-text-white'
                : 'tw-text-gray-300 hover:tw-bg-gray-700 hover:tw-text-white'
            ]"
          >
            <span class="tw-mr-2">📦</span> My Orders
          </button>
        </li>
        <li class="tw-mb-2">
          <button
              @click="navigateTo('AccountAddressBook')"
              :class="[
              'tw-w-full tw-text-left tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium tw-flex tw-items-center',
              isActive('AccountAddressBook')
                ? 'tw-bg-gray-900 tw-text-white'
                : 'tw-text-gray-300 hover:tw-bg-gray-700 hover:tw-text-white'
            ]"
          >
            <span class="tw-mr-2">🚛</span> Delivery Addresses
          </button>
        </li>
        <li class="tw-mb-2">
          <button
              @click="navigateTo('AccountAftersales')"
              :class="[
              'tw-w-full tw-text-left tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium tw-flex tw-items-center',
              isActive('AccountAftersales')
                ? 'tw-bg-gray-900 tw-text-white'
                : 'tw-text-gray-300 hover:tw-bg-gray-700 hover:tw-text-white'
            ]"
          >
            <span class="tw-mr-2">🔧</span> Aftersales
          </button>
        </li>
      </ul>
    </nav>

    <div>
      <button
          @click="logoutUser"
          class="tw-w-full tw-text-left tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium tw-flex tw-items-center tw-text-red-400 hover:tw-bg-red-900 hover:tw-text-red-200"
      >
        <span class="tw-mr-2">🚪</span> Log out
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAccountStore } from '@/stores/AccountStore';
import {useCoreStore} from "@/stores/CoreStore";

export default {
  name: 'SidebarNav',
  inject: ['coreService'],
  setup() {
    const coreStore = useCoreStore();
    const accountStore = useAccountStore()

    return {coreStore, accountStore};
  },
  methods: {
    // Map actions from the Pinia store
    ...mapActions(useAccountStore, ['setActivePage', 'logout']),

    /**
     * Navigates to the specified page by updating the store.
     * @param {string} pageName - The name of the page component to navigate to.
     */
    navigateTo(pageName) {
      this.setActivePage(pageName);
    },

    /**
     * Checks if the given page name is the currently active page.
     * @param {string} pageName - The name of the page to check.
     * @returns {boolean} - True if the page is active, false otherwise.
     */
    isActive(pageName) {
      return this.accountStore.activePage === pageName;
    },

    /**
     * Calls the logout action from the store.
     */
    logoutUser() {
      this.coreService.logout()
      this.coreService.changePage('login')
    }
  },
};
</script>

<style scoped>
/* Add any component-specific styles here if needed */
/* Using Tailwind classes primarily */
</style>
