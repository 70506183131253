<template>
  <div class="tw-bg-blue-600 tw-text-white tw-p-4 tw-flex tw-justify-between tw-items-center">
    <h1
        class="tw-text-lg tw-font-semibold tw-cursor-pointer tw-underline hover:tw-text-blue-300"
        @click="$emit('edit-quote')"
    >
      {{ quoteName }}
    </h1>

    <div class="tw-flex tw-items-center">
      <button
          v-if="mode"
          class="tw-bg-blue-800 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-mr-4"
          @click="$emit('change-mode')"
      >
        Return To Quote
      </button>
      <button
          v-else
          class="tw-bg-blue-800 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-mr-4"
          @click="quit"
      >
        Quit Quote Builder
      </button>

      <div class="tw-relative">
        <button
            class="tw-bg-blue-800 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-flex tw-items-center"
            @click="expanded = !expanded"
        >
          <span class="tw-mr-2">Quote Value: £{{ totalQuoteValue.toFixed(2) }}</span>
          <svg
              :class="[expanded ? 'tw-rotate-180' : 'tw-rotate-0']"
              class="tw-h-4 tw-w-4 tw-transform tw-transition-transform tw-duration-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>

        <transition name="fade">
          <div
              v-if="expanded"
              class="tw-absolute tw-right-0 tw-mt-2 tw-bg-white tw-shadow-md tw-rounded-md tw-w-64 tw-overflow-hidden tw-z-10"
          >
            <table class="tw-min-w-full tw-divide-y tw-divide-gray-200">
              <thead class="tw-bg-gray-50">
              <tr>
                <th class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Frame
                </th>
                <th class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Value
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(frame, index) in frames"
                  :key="frame.id"
              >
                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                  Frame #{{ index + 1 }}
                </td>
                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  £{{ frame.price * frame.quantity }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </transition>
      </div>

      <button
          class="tw-bg-blue-800 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-ml-4"
          @click="$emit('email-quote')"
      >
        📧
      </button>
      <button
          class="tw-bg-blue-800 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-ml-4"
          @click="$emit('view-quote')"
      >
        📄
      </button>
      <button
          class="tw-bg-blue-800 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-ml-4"
          @click="$emit('add-frame')"
      >
        ➕ Add New Frame
      </button>
    </div>
  </div>
</template>

<script>
import {useQuoteStore} from "@/stores/QuoteStore";

export default {
  props: ['quoteName', 'mode'],
  emits: ['edit-quote', 'add-frame', 'change-mode'],
  data() {
    return {
      expanded: false,
      store: {},
      frames: {},
    }
  },
  computed: {
    totalQuoteValue() {
      let store = useQuoteStore();
      let frames = store.frames

      let totalValue = 0;
      frames.forEach(function (item) {
        totalValue += (parseFloat(item.price) * item.quantity)
      })
      return totalValue
    }
  },
  mounted() {
    this.store = useQuoteStore();
    this.frames = this.store.frames
  },
  methods: {
    quit() {
      window.location = process.env.VUE_APP_API_URL + '/admin/quotes/' + this.store.quoteObj.id;
    }
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>