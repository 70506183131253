<template>
  <div class="mobile-toolbar">
    <span class="price">£{{ designerStore.pricing.price }} <br /> <small> Inc VAT</small></span>
    <AddToCart
        @add-to-cart="$emit('submitCart')"
    />
    <a href="#image-container-new" class="view-design view-design-btn" @click="$emit('view-design')">View Design</a>
  </div>
</template>

<script>
import AddToCart from "@/components/backend/pages/designer/components/List/AddToCart.vue";
import {useDesignerStore} from '@/stores/DesignerStore';

export default {
  name: "MobileToolbar",
  components: {
    AddToCart
  },
  props: {
    price: {
      type: Number,
      required: true
    }
  },
  setup() {
    const designerStore = useDesignerStore();
    return {designerStore};
  },
};
</script>

<style scoped>
.mobile-toolbar .add-to-cart {
  margin-top: 0;
  padding: 12px 20px;
}

.mobile-toolbar .add-to-cart .default:before,
.mobile-toolbar .add-to-cart .default:after {
  top: 16px;
}

.view-design-btn {
  --color: #fff;
  --icon: var(--color);
  --cart: #fff;
  --dots: #fff;
  --background: #007bff;
  --shadow: rgba(0, 9, 61, 0.16);
  cursor: pointer;
  position: relative;
  outline: none;
  margin-left: 5px;
  border: none;
  font-size: 16px;
  border-radius: 22px;
  padding: 12px 20px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background: var(--background);
  transition: transform 0.3s, box-shadow 0.3s;
}
.view-design-btn .default {
  padding-left: 16px;
  position: relative;
  opacity: var(--o, 1);
  transform: scale(var(--s, 1));
  transition: transform 0.3s, opacity 0.3s;
  transition-delay: var(--d, 0.3s);
}

/* Hide toolbar on larger screens */
@media (min-width: 769px) {
  .mobile-toolbar {
    display: none !important;
  }
}

/* Mobile toolbar styles */
.mobile-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #ffffff;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100000;
}

/* Price styling */
.price {
  font-size: 15px;
  font-weight: bold;
  width: 20%;
}

/* Button styles */
.btn {
  padding: 10px 16px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  margin-left: 8px;
}

.add-to-cart {
  background-color: #28a745;
  color: white;
}

.view-design {
  background-color: #007bff;
  color: white;
}

/* Button hover effects */
.btn:hover {
  opacity: 0.8;
}
</style>
