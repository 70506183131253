<template>
  <div
      id="image-container-new"
      style="position: relative;"
      :style="{ height: imageHeight + 'px' }"
  >
    <template
        v-for="image in designerStore.images"
        :key="image.id"
    >
      <img
          :style="imageStyles(image)"
          :data-extra-class="image.extraClass"
          :data-layer="image.id"
          :class="['tw-product-img', image.extraClass]"
          :src="image.image"
          oncontextmenu="return false;"
          draggable="false"
          @load="onImageLoad"
      >
    </template>
  </div>
</template>

<script>
import {useDesignerStore} from "@/stores/DesignerStore";

export default {
  name: "ImageLayering",
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  },
  data() {
    return {
      imageHeight: 450
    }
  },
  methods: {
    imageStyles(image) {
      let transform = '';
      let margin = 0;

      if (image.flipped) {
        transform += 'scaleX(-1) ';
      }

      if (this.designerStore.selectedVariant.image_zoom && this.designerStore.selectedVariant.image_zoom !== 1) {
        transform += `scale(${this.designerStore.selectedVariant.image_zoom}) `;
        margin = ((this.designerStore.selectedVariant.image_zoom - 1) * 100) + 10;
      }

      transform += 'translate(-50%, -50%)'

      return {
        marginTop: `${margin}px`, // Ensure margin is a valid CSS value
        zIndex: image.zindex,
        transform: transform.trim(),
        transformOrigin: 'left'
      };
    },
    measureImageHeight() {
      const img = document.querySelector('.tw-product-img')
      if (img) {
        this.imageHeight = img.clientHeight
      }
    },
    onImageLoad() {
      this.$nextTick(() => {
        this.measureImageHeight()
      })
    }
  }
}
</script>

<style scoped>
.tw-product-img {
  max-height: 500px;
  max-width: 100%;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#image-container {
  height: 100vh;
}
</style>
