<template>
  <div class="tw-pb-8">
    <div class="tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden">
      <!-- Header -->
      <h5
          class="tw-text-white tw-bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] tw-p-4"
          :style="{
            backgroundColor: designerStore.branding.branding_colour
          }"
      >
        Dimensions
      </h5>
      <!-- Card body -->
      <div class="tw-p-4">
        <div class="tw-flex tw-flex-wrap">
          <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5 tw-w-full">
            <template
                v-for="input in designerStore.product.dimensionFields"
                :key="input.input_name"
            >

              <div
                v-if="input.input_name"
              >
                <label :for="input.id" class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">{{ input.input_name }}</label>
                <input
                    :id="input.id"
                    v-model="designerStore.selectedOptions[input.id]"
                    v-debounce:2s="inputUpdated"
                    type="text"
                    style="padding: 5px"
                    class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full p-2.5"
                    placeholder=""
                    required
                />
                <small
                  v-if="input.min"
                >Min: {{ input.min }}mm, Max {{ input.max }}mm</small>
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from '@/stores/DesignerStore';
import {inject} from "vue";

export default {
  name: "DimensionsInput",
  setup() {
    const designerStore = useDesignerStore();
    const designerService = inject('designerService')

    return {designerStore , designerService};
  },
  methods: {
    inputUpdated() {
      this.$emit('layer-update', 'width')
    }
  }
}
</script>

<style scoped>
/* Tailwind CSS is assumed to be included globally. */
</style>
