// src/stores/accountStore.js
import { defineStore } from 'pinia';

/**
 * Pinia store for managing account page state.
 */
export const useAccountStore = defineStore('account', {
    // State properties
    state: () => ({
        // The currently active page/component name
        activePage: 'AccountOverview', // Default page
        loading: false,
        user: {
            name: 'Will Rich',
            email: 'will@williamrich.dev', // Using example.com for privacy
            avatar: 'https://placehold.co/40x40/E0E7FF/4F46E5?text=WR', // Placeholder avatar
            phone: '+1234 567 890',
        },
        // Mock order data
        orders: [],
        // Mock address book data
        addressBook: [
            { id: 1, name: 'Home', street: 'Miles Drive', city: 'Newark', state: 'NJ', zip: '07103', country: 'USA' },
            { id: 2, name: 'Work', street: 'Business Rd', city: 'Metropolis', state: 'NY', zip: '10001', country: 'USA' },
        ],
        // Mock aftersales data (returns/issues)
        aftersales: [
            { id: 'RMA001', orderId: 'FWB125467665', item: 'Product X', reason: 'Defective', status: 'Processing', date: '2024-04-10' },
            { id: 'RMA002', orderId: 'FWB125467550', item: 'Product Y', reason: 'Wrong size', status: 'Approved', date: '2024-03-20' },
        ]
    }),

    // Getters (computed properties based on state)
    getters: {
        // Get the current user data
        getUser: (state) => state.user,
        // Get the list of orders
        getOrders: (state) => state.orders,
        // Get the address book entries
        getAddressBook: (state) => state.addressBook,
        // Get the aftersales records
        getAftersales: (state) => state.aftersales,
        // Get only the 3 most recent orders for the overview page
        getRecentOrders: (state) => state.orders.slice(0, 3),
    },

    // Actions (methods to modify state)
    actions: {
        /**
         * Sets the currently active page.
         * @param {string} pageName - The name of the component/page to activate.
         */
        setActivePage(pageName) {
            this.activePage = pageName;
        }
    },
});
