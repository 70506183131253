import axios from "axios";
import {useCoreStore} from "@/stores/CoreStore";
import {useAccountStore} from "@/stores/AccountStore";
export default class CoreService {
    constructor(pageData) {
        const coreStore = useCoreStore();
        this.baseUrl = process.env.VUE_APP_API_URL
        this.pageData = pageData
        this.id = pageData.productId

        // Create Axios instance with base URL
        this.axios = axios.create({
            baseURL: this.baseUrl,
        });

        this.initApiAuth()

        this.getBranding()
        coreStore.page = 'pg-' + pageData.page

        this.loadCore();
    }

    initApiAuth() {
        const coreStore = useCoreStore();

        let sessId = null;

        try {
            sessId = localStorage.getItem('session-token');
            const now = new Date()
            if (sessId) {
                let sessionItem = JSON.parse(sessId);

                if (now.getTime() > sessionItem.expiry) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem('session-token')
                } else {
                    coreStore.auth.token = sessionItem;
                    this.authoriseApi()
                }
            } else {
                coreStore.auth.loading = false
            }
        } catch (e) {
            console.log(e)
        }

        this.authoriseApi()

    }
    authoriseApi() {
        const coreStore = useCoreStore();
        if (coreStore.auth.token.token) {
            this.axios.interceptors.request.use(function (config) {
                let token = coreStore.auth.token.token;

                if (token !== null) {
                    config.headers.Authorization = 'Bearer ' + token;
                }

                return config;
            },function (error) {
                console.error(error)

                // Check if the error is due to an HTTP response status code
                if (error.response) {
                    // Check specifically for 401 Unauthorized
                    if (error.response.status === 401) {
                        coreStore.auth.authenticated = false;
                    }
                }
            });

            this.checkAuth();
        }
    }


    async logout () {
        const coreStore = useCoreStore();
        const accountStore = useAccountStore()

        await this.axios.post('/sqspace/logout')

        accountStore.user = null
        coreStore.auth.user = []
        coreStore.auth.authenticated = false;
        coreStore.auth.token = null;
        accountStore.orders = []
        accountStore.aftersales = []
        accountStore.addressBook = []
    }
    changePage(page) {
        const coreStore = useCoreStore();
        coreStore.page = 'pg-' + page;
    }

    // SECTION AUTH

    async login(data) {
        try {
            const coreStore = useCoreStore();
            const accountStore = useAccountStore()

            // Attempt the POST request
            let response = await this.axios.post('/sqspace/login?domain=' + coreStore.branding.domain, data);

            // If axios didn't throw, it means we got a 2xx status code, implying success here.
            if (response && response.data && response.data.authorisation && response.data.authorisation.token) {
                // Extract token and expiry
                let item = {
                    token: response.data.authorisation.token,
                    expires: response.data.authorisation.expires
                };

                accountStore.user = response.data.customer
                coreStore.auth.user = response.data.customer
                coreStore.auth.authenticated = true;
                coreStore.auth.token = item;
                // Store the token in localStorage
                localStorage.setItem('session-token', JSON.stringify(item));

                // Authentication successful
                return true;
            } else {
                console.error("Login response structure unexpected:", response?.data);
                return false; // Treat unexpected structure as failure
            }

        } catch (error) {
            // Authentication failed (due to 401 or any other error caught)
            return false;
        }
    }

    async getSessionId() {
        let  urlParams = new URLSearchParams(window.location.search);
        let sessIdOverride = urlParams.get('sessIdOVR')

        if (sessIdOverride) {
            return sessIdOverride;
        }

        let sessId = localStorage.getItem('sessId');
        if (!sessId) {
            return await this.setSessionId()
        }

        const now = new Date()
        let sessionItem = JSON.parse(sessId);

        if (now.getTime() > sessionItem.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem('sessId')
            sessId = await this.setSessionId();
        } else {
            sessId = sessionItem.value;
        }

        return sessId
    }

    async setSessionId() {
        let sessIdReq = await this.axios.get( '/api/session')
        let sessid = sessIdReq.data.session_id;
        const now = new Date()
        const ttl = 1440;

        const item = {
            value: sessid,
            expiry: now.getTime() + (ttl*60)*1000,
        }

        localStorage.setItem('sessId', JSON.stringify(item));

        return sessid;

    }

    async checkAuth() {
        const coreStore = useCoreStore();

        try {
            let response = await this.axios.get('api/sqspace/auth/user');

            if (response.data) {
                coreStore.auth.user = response.data
                coreStore.auth.authenticated = true;
                coreStore.auth.loading = false;
            }
        } catch (e) {
            coreStore.auth.loading = false;
            coreStore.auth.authenticated = false;
            localStorage.removeItem('session-token')

        }
    }
    async loadCore() {
        await this.sortSession()
        await this.loadCart()
    }

    async loadCart() {
        const coreStore = useCoreStore();
        coreStore.cart.loading = true;
        let cartResponse = await this.axios.get('/api/cart?sessId=' + coreStore.cart.sessionId);

        coreStore.cart.object = cartResponse.data.cart;
        coreStore.cart.cartItems = cartResponse.data.items;
        coreStore.cart.values = cartResponse.data.values;
        coreStore.cart.loading = false
    }

    async sortSession() {
        const coreStore = useCoreStore();
        coreStore.cart.sessionId = await this.getSessionId()
        return true;
    }

    // SECTION AUTH END

    async getBranding() {
        const coreStore = useCoreStore();
        let requestUrl = window.location.hostname;

        let branding = await this.axios.post('/api/branding/lookup', {
            domain: requestUrl
        })

        coreStore.branding = branding.data;
        coreStore.coreLoading = false;
    }

    // SECTION CART
    async saveCart(email) {
        const coreStore = useCoreStore();

        let postData = {
            'sessId': coreStore.cart.sessionId,
            'email': email,
            'domain': coreStore.branding.domain
        }

        let savePost = await this.axios.post( '/api/save/cart', postData);

        if (savePost.data.result && savePost.data.result == 'Success') {
            return true;
        }

        return savePost.data.message;
    }

    async requestCallback(data) {
        const coreStore = useCoreStore();

        let postData = {
            'sessId': coreStore.cart.sessionId,
            'name': data.name,
            'phone': data.phone,
            'domain': coreStore.branding.domain
        }

        let savePost = await this.axios.post( '/api/cart/action/callback', postData);

        if (savePost.data.result && savePost.data.result == 'Success') {
            return true;
        }

        return savePost.data.message;
    }

    async addVoucher(voucher) {
        const coreStore = useCoreStore();

        let postData = {
            'sessId': coreStore.cart.sessionId,
            'voucher': voucher,
            'domain': coreStore.branding.domain
        }

        let savePost = await this.axios.post( '/api/cart/voucher/add', postData);
        this.loadCart()

        if (savePost.data.status && savePost.data.status == 'success') {
            return true;
        }

        return savePost.data.message;
    }

    async removeVoucher() {
        const coreStore = useCoreStore();

        let postData = {
            'sessId': coreStore.cart.sessionId,
        }

        let savePost = await this.axios.post( '/api/cart/voucher/remove', postData);
        this.loadCart()
    }

    async removeCartItem(itemId) {
        const coreStore = useCoreStore();

        let postData = {
            'sessId': coreStore.cart.sessionId,
        }

        let response = await this.axios.post('/api/cart/' + itemId, postData);
        this.loadCart()
    }

    // SECTION CART END

    // SECTION ACCOUNT

    async loadAccount() {
        const accountStore = useAccountStore();
        accountStore.loading = true;
        this.authoriseApi()

        let response = await this.axios.get('api/account/get');

        if (response.data.orders) {
            accountStore.orders = response.data.orders
        }

        if (response.data.address) {
            accountStore.addressBook = response.data.address
        }

        if (response.data.aftersales) {
            accountStore.aftersales = response.data.aftersales
        }

        accountStore.loading = false;

    }

    async createOrUpdateAddress (payload) {

        try {
            let response = await this.axios.post('api/account/address', payload);

            if (response.data) {
                this.loadAccount()
            }
            return true
        } catch (e) {
            console.error(e)
            return false;
        }

    }

    async createAftersales (payload) {

        try {
            let response = await this.axios.post('api/account/aftersales', payload);

            if (response.data) {
                this.loadAccount()
            }
            return true
        } catch (e) {
            console.error(e)
            return false;
        }

    }

}