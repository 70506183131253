<template>
  <div class="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-75 tw-z-50">
    <div class="tw-bg-white tw-w-full tw-h-full tw-p-5 tw-overflow-auto">
      <button
          class="tw-bg-red-600 hover:tw-bg-red-500 tw-text-white tw-font-bold tw-py-1 tw-px-2 tw-rounded tw-absolute tw-top-0 tw-right-0 tw-m-2"
          @click="$emit('close')"
      >
        X
      </button>
      <h2 class="tw-font-semibold tw-text-xl tw-mb-4">
        Select Product
      </h2>
      <div class="tw-grid tw-grid-cols-6">
        <template
            v-for="(product, index) in products"
            :key="index"
        >
          <div class="tw-col-span-6" style="padding: 10px">
            <h4 class="tw-text-2xl tw-font-extrabold dark:text-white"> {{ index }} </h4>
            <hr>
          </div>

          <div
              v-for="prod in product"
              :key="prod.id"
              @click="$emit('add-product', prod)"
              class="tw-product-card tw-cursor-pointer tw-p-4 tw-border tw-rounded tw-shadow"
          >
            <img
                :src="prod.image"
                :alt="prod.name"
                class="tw-mb-2"
            >
            <h3 class="tw-font-semibold tw-mb-1">
              {{ prod.name }}
            </h3>
          </div>

        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuotingSidebar",
  props: ['products'],
  emits: ['close', 'add-product']
};
</script>

<style scoped>
hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333;  /* old IE */
  background-color: #333;  /* Modern Browsers */
}
</style>
