<template>
  <nav class="tw-bg-white tw-shadow" id="main-navbar">
    <div class="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
      <div class="tw-h-18">
        <div>
          <ul class="tw-relative tw-flex tw-flex-row tw-gap-x-2">
            <!-- Item -->
            <li
                v-for="(tab, index) in designerStore.product.tabs"
                :key="tab"
                class="tw-flex tw-items-center tw-gap-x-2 tw-shrink tw-basis-0 tw-flex-1 tw-group"
            >
              <div class="tw-min-w-7 tw-min-h-7 tw-inline-flex tw-justify-center tw-items-center tw-text-xs tw-align-middle">
                <span
                    class="tw-size-7 tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-bg-gray-800 tw-font-medium tw-text-white tw-rounded-full">
                  {{ index + 1 }}
                </span>
                <span class="tw-ms-2 tw-block tw-text-sm tw-font-medium tw-text-gray-800">
                  {{ normaliseTabName(tab) }}
                </span>
              </div>
              <div class="tw-w-full tw-h-px tw-flex-1 tw-bg-gray-200 group-last:tw-hidden dark:tw-bg-neutral-700"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useDesignerStore } from "@/stores/DesignerStore";

export default {
  name: "DesignerToolbar",
  setup() {
    const designerStore = useDesignerStore();
    return { designerStore };
  },
  methods: {
    normaliseTabName(tabName) {
      let itemName = tabName.replace(/_/g, ' ');
      itemName = itemName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      return itemName;
    }
  }
};
</script>

<style scoped>
#steps-content {
  width: 100%;
}
</style>
