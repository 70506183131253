<template>
  <div class="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-75 tw-z-50 tw-py-5">
    <div class="tw-bg-white tw-w-1/2 tw-h-3/4 tw-p-5 tw-m-auto tw-rounded tw-overflow-auto">
      <button
          class="tw-bg-red-600 hover:tw-bg-red-500 tw-text-white tw-font-bold tw-py-1 tw-px-2 tw-rounded tw-absolute tw-top-0 tw-right-0 tw-m-2"
          @click="$emit('close')"
      >
        X
      </button>
      <h2 class="tw-font-semibold tw-text-xl tw-mb-4">
        Customize
      </h2>

      <form @submit.prevent="saveCustomizations">
        <CustomizationField
            v-for="(item, index) in frame.custom_attributes"
            :key="index"
            :item="item"
            @remove="removeItem(index)"
        />

        <button
            type="button"
            class="tw-mt-4 tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
            @click="addItem"
            style="margin-right: 20px"
        >
          Add New Item
        </button>
        <button
            type="submit"
            class="tw-mt-4 tw-bg-green-600 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
        >
          Save Customizations
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import CustomizationField from './CustomizationField.vue';

export default {
  components: { CustomizationField },
  props: ['frame'],
  emits: ['close', 'save-customizations'],

  methods: {
    addItem() {
      if (!this.frame.custom_attributes) {
        this.frame.custom_attributes = [];
      }
      this.frame.custom_attributes.push({ key: '', value: '', visible: 0, price: 0 });
    },
    removeItem(index) {
      this.frame.custom_attributes.splice(index, 1);
    },
    saveCustomizations() {
      console.log('Saving customizations:', this.frame.custom_attributes);
      this.$emit('save-frame', this.frame);
      this.$emit('close');
    }
  }
};
</script>