<template>
  <div class="tw-text-sm tw-font-medium tw-text-center tw-text-gray-500 tw-border-b tw-border-gray-200 dark:tw-text-gray-400 dark:tw-border-gray-700">
    <ul class="tw-flex tw-flex-wrap -tw-mb-px">
      <li
          v-for="layer in designerStore.product.tabLayers"
          :key="layer.id"
          class="tw-me-2"
      >
        <a
            href="#"
            :class="[
              'tw-inline-block tw-p-4 tw-border-b-2 tw-rounded-t-lg',
              designerStore.product.activeLayerTab.id === layer.id
                ? 'tw-border-blue-600 tw-text-blue-600' // class when active
                : 'tw-border-transparent hover:tw-text-gray-600 hover:tw-border-gray-300' // default classes
            ]"
            @click="designerStore.product.activeLayerTab = layer"
        >
          {{ layer.name }}
        </a>
      </li>
    </ul>

    <LayerSelector
        v-if="!selectedLayer.empty"
        :layer="selectedLayer"
        @layer-update="layerUpdate"
    />

    <div
        class="tw-block"
        style="margin-top: 20px"
    >
      <button
          class="tw-btn tw-btn-primary-outline tw-btn"
          style="border-radius: 30px; width: 200px;margin: 0 20px;"
          @click="previousTab()"
      >
        Previous
      </button>
      <button
          class="tw-btn tw-btn-primary tw-btn-next"
          style="border-radius: 30px; width: 200px; margin: 0 20px;"
          @click="nextTab()"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import {useDesignerStore} from "@/stores/DesignerStore";
import LayerSelector from "@/components/backend/pages/designer/components/List/LayerSelector.vue";

export default {
  name: "LayerSelectCard",
  components: {
    LayerSelector,
  },
  setup() {
    const designerStore = useDesignerStore();

    return {designerStore};
  },
  computed: {
    selectedLayer() {
      return this.designerStore.product.layers[this.designerStore.product.activeLayerTab.id]
    }
  },
  methods: {
    layerUpdate(layerId) {
      this.$emit('layer-update', layerId)
    },
    nextTab() {
      this.$emit('next-tab')
    },
    previousTab() {
      this.$emit('previous-tab')
    }
  }
}
</script>

<style scoped>
#steps-content {
  width: 100%;
}
</style>
